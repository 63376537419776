import { Housing } from "@/store/subscription/housing/type";
import VueI18n from "vue-i18n";
import { BillingAddress } from "@/store/subscription/bankDetails/type";

export const getStringOrDefault = (
  value?: string | number | null | VueI18n.TranslateResult,
  defaultValue = "-"
): string => {
  return typeof value !== "undefined" && value !== null && value !== ""
    ? String(value)
    : defaultValue;
};

export const getShortPhysicalAddress = (physicalAddress: Housing): string => {
  const usedAddressFields: Array<keyof Housing> = [
    "street",
    "postCode",
    "city",
  ];
  const isAddressInvalid = usedAddressFields.some(
    (addressField) =>
      typeof physicalAddress[addressField] === "undefined" ||
      physicalAddress[addressField] === null ||
      physicalAddress[addressField] === ""
  );
  if (isAddressInvalid) {
    return "-";
  }
  const streetNumberSuffix = physicalAddress.streetNumberSuffix || "";
  return `${physicalAddress.streetNumber} ${streetNumberSuffix} ${physicalAddress.street} ${physicalAddress.postCode} ${physicalAddress.city}`;
};

export const getShortBillingAddress = (
  billingAddress: BillingAddress
): string => {
  const usedAddressFields: Array<keyof BillingAddress> = [
    "street",
    "postCode",
    "city",
  ];
  const isAddressInvalid = usedAddressFields.some(
    (addressField) =>
      typeof billingAddress[addressField] === "undefined" ||
      billingAddress[addressField] === null ||
      billingAddress[addressField] === ""
  );
  if (isAddressInvalid) {
    return "-";
  }
  const streetNumberSuffix = billingAddress.streetNumberSuffix || "";
  return `${billingAddress.streetNumber} ${streetNumberSuffix} ${billingAddress.street} ${billingAddress.postCode} ${billingAddress.city}`;
};
