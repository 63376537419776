import {
  NotificationProgrammatic as Notification,
  ToastProgrammatic as Toast,
} from "buefy";
import { NotificationState, TypeNotification } from "@/store/notification/type";

export const state: NotificationState = {
  position: "is-bottom-right",
  queue: false,
  duration: Number(process.env.VUE_APP_DISPLAY_NOTIFICATION_DURATION) || 5000,
};

export const getters = {
  getIcon: () => (type: TypeNotification) => {
    let icon: string;
    switch (type) {
      case TypeNotification.IsWarning:
        icon = "mdi-alert-circle-outline";
        break;
      case TypeNotification.IsDanger:
        icon = "mdi-close";
        break;
      default:
        icon = "mdi-check";
        break;
    }
    return icon;
  },
  getIconColorClass: () => (type: TypeNotification) => {
    let iconClass: string;
    switch (type) {
      case TypeNotification.IsWarning:
        iconClass = "darkWarning";
        break;
      case TypeNotification.IsDanger:
        iconClass = "darkDanger";
        break;
      default:
        iconClass = "darkSuccess";
        break;
    }
    return iconClass;
  },
};

export const actions = {
  openNotification: {
    root: true,
    handler(
      namespacedContext: any,
      {
        message,
        type = TypeNotification.IsSuccess,
        duration = namespacedContext.state.duration,
      }: { message: string; type: TypeNotification; duration?: number }
    ) {
      const iconColorClass = namespacedContext.getters.getIconColorClass(type);
      const icon = namespacedContext.getters.getIcon(type);
      const HtmlMessage = `
              <span 
                style="vertical-align: middle; padding-right: 1em;" 
                class='icon is-medium has-text-${iconColorClass}'>
                <i class='mdi ${icon} mdi-36px'></i>
              </span>
              <span style="vertical-align: middle">${message}</span>
            `;

      if (duration === 0) {
        Notification.open({
          type,
          message: HtmlMessage,
          position: namespacedContext.state.position,
          queue: namespacedContext.state.queue,
          indefinite: true,
        });
      } else {
        Toast.open({
          type,
          message: HtmlMessage,
          position: namespacedContext.state.position,
          queue: namespacedContext.state.queue,
          duration,
        });
      }
    },
  },
};

const notification = {
  namespaced: true,
  state,
  getters,
  actions,
};

export default notification;
