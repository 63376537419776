import {
  BatteryCapacity,
  BatteryPlan,
  GetOfferQuery,
  Offer,
  OfferState,
  ProductType,
} from "@/store/subscription/offer/type";
import { PRODUCT_TYPES } from "@/store/subscription/offer/constant";

import apiBatteryPlan from "@/api/batteryPlan";
import { AxiosResponse } from "axios";

const state: OfferState = {
  batteryPlans: [],
  batteryPlansLoading: false,
  batteryPlansError: null,
  batteryCapacity: null,
  productTypes: PRODUCT_TYPES,
  productType: ProductType.GREEN100,
};

const getters = {
  getProductTypes(state: OfferState): ProductType[] {
    return state.productTypes;
  },
  getBatteryPlans(state: OfferState): BatteryPlan[] {
    return state.batteryPlans;
  },
  getBatteryPlansLoading(state: OfferState): boolean {
    return state.batteryPlansLoading;
  },
  getBatteryPlansError(state: OfferState): string | null {
    return state.batteryPlansError;
  },
  getProductType(state: OfferState): ProductType | null {
    return state.productType;
  },
  getBatteryCapacity(state: OfferState): BatteryCapacity | null {
    return state.batteryCapacity;
  },
  isOfferStepValid(state: OfferState): boolean {
    return !!state.productType && !!state.batteryCapacity;
  },
  getOffer(state: OfferState): Offer {
    return {
      productType: state.productType,
      batteryCapacity: state.batteryCapacity,
    };
  },
};

const mutations = {
  setGetBatteryPlansRequest(state: OfferState) {
    state.batteryPlansError = null;
    state.batteryPlansLoading = true;
  },
  setGetBatteryPlansSuccess(state: OfferState, batteryPlans: BatteryPlan[]) {
    state.batteryPlansError = null;
    state.batteryPlans = batteryPlans;
    state.batteryPlansLoading = false;
  },
  setGetBatteryPlansFailure(state: OfferState, errorMessage: string) {
    state.batteryPlansError = errorMessage;
    state.batteryPlans = [];
    state.batteryPlansLoading = false;
  },
  setProductType(state: OfferState, productType: ProductType) {
    state.productType = productType;
  },
  setBatteryCapacity(state: OfferState, batteryCapacity: BatteryCapacity) {
    state.batteryCapacity = batteryCapacity;
  },
};

const actions = {
  getBatteryPlans: (context: any) => {
    context.commit("setGetBatteryPlansRequest");

    const getBatteryPlansSuccess = (batteryPlans: BatteryPlan[]) => {
      context.commit("setGetBatteryPlansSuccess", batteryPlans);
    };
    const getBatteryPlansFailure = (error: AxiosResponse) => {
      const errorMessage = error.data?.title || error.statusText;
      context.commit("setGetBatteryPlansFailure", errorMessage);
    };

    apiBatteryPlan
      .getBatteryPlans()
      .then(getBatteryPlansSuccess)
      .catch(getBatteryPlansFailure);
  },
  setOffer: (context: any, offer: GetOfferQuery) => {
    context.commit("setProductType", offer.productType);
    context.commit("setBatteryCapacity", offer.batteryCapacity);
  },
};

const offer = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default offer;
