import {
  NavigationState,
  RouteName,
  SubscriptionStep,
} from "@/store/navigation/type";

export const state: NavigationState = {
  mainHeaderTitle: "",
  currentStep: null,
  visitedSteps: {},
};
export const getters = {
  getMainHeaderTitle: () => {
    return state.mainHeaderTitle;
  },
  getCurrentStep: () => {
    return state.currentStep;
  },
  checkIfStepVisited:
    (state: NavigationState) =>
    (step: SubscriptionStep): boolean => {
      return !!state.visitedSteps[step];
    },
  checkIfStepFinished:
    (
      state: NavigationState,
      getters: any,
      rootState: any,
      rootGetters: { [x: string]: any }
    ) =>
    (step: SubscriptionStep): boolean => {
      switch (step) {
        case RouteName.ContactInformation:
          return rootGetters[
            "subscription/contactInformation/isContactInformationStepValid"
          ];
        case RouteName.Housing:
          return rootGetters["subscription/housing/isHousingStepValid"];
        case RouteName.Offer:
          return rootGetters["subscription/offer/isOfferStepValid"];
        case RouteName.Billing:
          return rootGetters["subscription/billing/isBillingStepValid"];
        case RouteName.BankDetails:
          return rootGetters["subscription/bankDetails/isBankDetailsStepValid"];
        case RouteName.Contract:
          return rootGetters["subscription/contract/isContractStepValid"];
        default:
          return false;
      }
    },
};
export const mutations = {
  setMainHeaderTitle: (state: NavigationState, mainHeaderTitle: string) => {
    state.mainHeaderTitle = mainHeaderTitle;
  },
  setCurrentStep: (state: NavigationState, currentStep: RouteName) => {
    state.currentStep = currentStep;
  },
  setVisitedStep: (state: NavigationState, step: SubscriptionStep) => {
    state.visitedSteps[step] = true;
  },
};

export const actions = {};

const navigation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default navigation;
