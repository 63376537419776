import { BatteryPlan } from "@/store/subscription/offer/type";
import { httpService } from "@/api";

const getBatteryPlans = (): Promise<BatteryPlan[]> => {
  const defaultTenant = "MYL";
  const tenant = process.env.VUE_APP_TENANT || defaultTenant;
  return httpService.get(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/batteryPlans?tenant=${tenant}`
  );
};

export default {
  getBatteryPlans,
};
