import { TypeNotification } from "@/store/notification/type";
import store from "@/store";
import VueI18n from "vue-i18n";

const sendNotification = (
  message: VueI18n.TranslateResult | string,
  type: TypeNotification,
  duration?: number
) => {
  store.dispatch(
    "openNotification",
    {
      message,
      type,
      duration,
    },
    { root: true }
  );
};

const sendError = (
  message: VueI18n.TranslateResult | string,
  duration?: number
) => sendNotification(message, TypeNotification.IsDanger, duration);

const sendSuccess = (message: VueI18n.TranslateResult | string) =>
  sendNotification(message, TypeNotification.IsSuccess);

const sendWarning = (message: VueI18n.TranslateResult | string) =>
  sendNotification(message, TypeNotification.IsWarning);

export default {
  sendError,
  sendSuccess,
  sendWarning,
};
