import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import store from "@/store";

class HttpService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = this.setAxiosInstance();
  }

  public get(path: string, config?: AxiosRequestConfig) {
    return this.axiosInstance
      .get(path, config)
      .then((response) => Promise.resolve(response.data));
  }

  public getFullResponse(path: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.get(path, config);
  }

  public post(path: string, payload?: any, config?: AxiosRequestConfig) {
    return this.axiosInstance
      .post(path, payload, config)
      .then((response) => Promise.resolve(response.data));
  }

  public put(path: string, payload?: any, config?: AxiosRequestConfig) {
    return this.axiosInstance
      .put(path, payload, config)
      .then((response) => Promise.resolve(response.data));
  }

  public patch(path: string, payload?: any, config?: AxiosRequestConfig) {
    return this.axiosInstance
      .patch(path, payload, config)
      .then((response) => Promise.resolve(response.data));
  }

  public delete(path: string, config?: AxiosRequestConfig) {
    return this.axiosInstance
      .delete(path, config)
      .then((response) => Promise.resolve(response.data));
  }

  private setAxiosInstance() {
    const axiosInstance = axios.create();
    axiosInstance.interceptors.response.use(
      this.handleSuccess,
      this.handleError
    );
    return axiosInstance;
  }

  private handleSuccess(
    response: AxiosResponse<any> | Promise<AxiosResponse<any>>
  ) {
    return response;
  }

  private handleError = (error: AxiosError) => {
    let response;
    switch (error?.response?.status) {
      case 500:
        store.dispatch(
          "openNotification",
          {
            message: `${error.response?.status}: ${error.response?.statusText}`,
            type: "is-danger",
          },
          { root: true }
        );
        response = Promise.reject(error.response);
        break;
      default:
        response = Promise.reject(error.response);
        break;
    }
    return response;
  };
}

export const httpService = new HttpService();
