export enum Prefix {
  MR = "MR",
  MRS = "MRS",
}

export enum Tenant {
  DOM = "DOM",
  MYL = "MYL",
}

export enum ClientType {
  RES = "RES",
  PRO = "PRO",
}

export interface ContactInformationState {
  companyName: string;
  companyIdentificationNumber: string;
  prefix: Prefix | null;
  familyName: string;
  firstName: string;
  email: string;
  mobilePhone: string;
  tenant: Tenant;
  clientType: ClientType;
  identificationNumber: number | null;
  cosignerExists: boolean;
  cosigner: Cosigner;
}

export interface GetContactInformationQuery {
  id: string;
  companyName: string;
  companyIdentificationNumber: string;
  email: string;
  prefix: Prefix;
  familyName: string;
  firstName: string;
  mobilePhone: string;
  tenant: Tenant;
  clientType: ClientType;
  cosigner: Cosigner;
}

export interface Cosigner {
  prefix: Prefix | null;
  familyName: string;
  firstName: string;
}

export interface ContactInformation {
  id: string;
  companyName: string;
  companyIdentificationNumber: string;
  email: string;
  prefix: Prefix;
  familyName: string;
  firstName: string;
  mobilePhone: string;
  tenant: Tenant;
  clientType: ClientType;
  cosigner: Cosigner;
}
