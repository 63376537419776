import Vue from "vue";
import Buefy from "buefy";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import moment from "moment";
import VueGtm from "@gtm-support/vue2-gtm";
import * as FullStory from "@fullstory/browser";
import VueTheMask from "vue-the-mask";

function loadTenantStyle() {
  if (process.env.VUE_APP_TENANT === "DOM") {
    require("./assets/styles/domuneo/all.scss");
  } else {
    require("./assets/styles/mylight/all.scss");
  }
}

function initMoment() {
  moment.locale("fr");
}

function initBuefy(Vue: any) {
  //TODO Load only component used on project
  Vue.use(Buefy);
}

function initVueMask(Vue: any) {
  Vue.use(VueTheMask);
}

const initSnippet = (Vue: any) => {
  try {
    Vue.use(VueGtm, {
      id: process?.env?.VUE_APP_GTM_ID,
      vueRouter: router,
    });
    FullStory.init({ orgId: process?.env?.VUE_APP_FULLSTORY_ID });
    Vue.prototype.$FullStory = FullStory;
  } catch (error) {
    console.error(error);
  }
};

export const initialize = () => {
  initMoment();
  loadTenantStyle();
  initBuefy(Vue);
  initSnippet(Vue);
  initVueMask(Vue);

  Vue.config.productionTip = false;

  new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
};
