<template>
  <b-steps
    :has-navigation="isMobile"
    :size="isMobile ? 'is-small' : ''"
    :value="currentStep"
    :vertical="!isMobile"
    animated
    label-position="bottom"
    mobile-mode="minimalist"
    step="contactInformation"
    @input="(step) => changeStep(step)"
  >
    <b-step-item
      :headerClass="
        currentStep === RouteName.Offer ? 'offerStep' : 'offerStep is-previous'
      "
      :icon="getIconType(RouteName.Offer)"
      :label="$t('offer.yourOffer')"
      :type="getIconColor(RouteName.Offer)"
      :value="RouteName.Offer"
    />
    <b-step-item
      :headerClass="
        currentStep === RouteName.ContactInformation
          ? 'contactInformationStep'
          : 'contactInformationStep is-previous'
      "
      :icon="getIconType(RouteName.ContactInformation)"
      :label="$t('contactInformation.yourContactDetails')"
      :type="getIconColor(RouteName.ContactInformation)"
      :value="RouteName.ContactInformation"
      :clickable="checkIfStepFinished(RouteName.Offer)"
    />
    <b-step-item
      :headerClass="
        currentStep === RouteName.Housing
          ? 'housingStep'
          : 'housingStep is-previous'
      "
      :icon="getIconType(RouteName.Housing)"
      :label="$t('housing.yourAccommodation')"
      :type="getIconColor(RouteName.Housing)"
      :value="RouteName.Housing"
      :clickable="checkIfStepFinished(RouteName.ContactInformation)"
    />
    <b-step-item
      :headerClass="
        currentStep === RouteName.Billing
          ? 'billingStep'
          : 'billingStep is-previous'
      "
      :icon="getIconType(RouteName.Billing)"
      :label="$t('billing.yourBilling')"
      :type="getIconColor(RouteName.Billing)"
      :value="RouteName.Billing"
      :clickable="checkIfStepFinished(RouteName.Housing)"
    />
    <b-step-item
      :headerClass="
        currentStep === RouteName.BankDetails
          ? 'bankDetailsStep'
          : 'bankDetailsStep is-previous'
      "
      :icon="getIconType(RouteName.BankDetails)"
      :label="$t('bankDetails.yourBankDetails')"
      :type="getIconColor(RouteName.BankDetails)"
      :value="RouteName.BankDetails"
      :clickable="checkIfStepFinished(RouteName.Billing)"
    />
    <b-step-item
      :headerClass="
        currentStep === RouteName.Contract
          ? 'contractStep'
          : 'contractStep is-previous'
      "
      :icon="getIconType(RouteName.Contract)"
      :label="$t('contract.yourContract')"
      :type="getIconColor(RouteName.Contract)"
      :value="RouteName.Contract"
      :clickable="checkIfStepFinished(RouteName.BankDetails)"
    />
    <template v-if="isMobile" slot="navigation" slot-scope="{ previous, next }">
      <div class="step-navigation">
        <b-button
          :disabled="previous.disabled"
          icon-left="arrow-left"
          type="is-primary"
          @click.prevent="previous.action"
        />
        <b-button
          :disabled="next.disabled"
          icon-left="arrow-right"
          type="is-primary"
          @click.prevent="next.action"
        />
      </div>
    </template>
  </b-steps>
</template>
<script>
import { RouteName } from "@/store/navigation/type";
import { mapGetters } from "vuex";

export default {
  name: "SubscriptionStepsNavigation",
  props: {
    isMobile: Boolean,
  },
  data() {
    return {
      RouteName,
    };
  },
  computed: {
    ...mapGetters("navigation", {
      currentStep: "getCurrentStep",
      checkIfStepVisited: "checkIfStepVisited",
      checkIfStepFinished: "checkIfStepFinished",
    }),
    getIconColor() {
      return (step) =>
        this.checkIfStepFinished(step)
          ? "is-primary"
          : this.checkIfStepVisited(step)
          ? "is-danger"
          : "is-grey";
    },
    getIconType() {
      return (step) =>
        this.checkIfStepFinished(step)
          ? "check-bold"
          : this.checkIfStepVisited(step)
          ? "close-thick"
          : "";
    },
  },
  methods: {
    changeStep: function (step) {
      this.$router.push({ name: step });
    },
  },
};
</script>
