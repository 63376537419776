import contactInformation from "@/store/subscription/contactInformation";
import housing from "@/store/subscription/housing";
import offer from "@/store/subscription/offer";
import billing from "@/store/subscription/billing";
import bankDetails from "@/store/subscription/bankDetails";
import contractSigning from "@/store/subscription/contractSigning";
import contractActivation from "@/store/subscription/contractActivation";
import contract from "@/store/subscription/contract";
import emailValidation from "@/store/subscription/emailValidation";
import gridOperator from "@/store/subscription/gridOperator";

import apiSubscription from "@/api/subscription";
import {
  GetSubscriptionQuery,
  SaveSubscriptionQuery,
  SubscriptionState,
} from "@/store/subscription/type";
import i18n from "@/i18n";
import notification from "@/store/notification/helper";
import { ApiError } from "@/api/type";
import { BillingAddress } from "@/store/subscription/bankDetails/type";

export const state: SubscriptionState = {
  getSubscriptionLoading: false,
  getSubscriptionError: null,
  saveSubscriptionLoading: false,
  saveSubscriptionError: null,
  updateSubscriptionEmailLoading: false,
  updateSubscriptionEmailError: null,
  subscriptionId: null,
};

export const getters = {
  getSubscriptionId(state: SubscriptionState) {
    return state.subscriptionId;
  },
  isGetSubscriptionLoading() {
    return state.getSubscriptionLoading;
  },
  isSaveSubscriptionLoading() {
    return state.saveSubscriptionLoading;
  },
  getUpdateSubscriptionEmailError() {
    return state.updateSubscriptionEmailError;
  },
  isGetSubscriptionError(): boolean {
    return !!state.getSubscriptionError;
  },
  isUpdateSubscriptionEmailLoading() {
    return state.updateSubscriptionEmailLoading;
  },
  isRegisteredSubscription(state: SubscriptionState) {
    return !!state.subscriptionId;
  },
  isSubscriptionValidForSave: (
    state: SubscriptionState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ) => {
    return (
      rootGetters["subscription/bankDetails/isBankDetailsStepValid"] &&
      rootGetters["subscription/housing/isHousingStepValid"] &&
      rootGetters[
        "subscription/contactInformation/isContactInformationStepValid"
      ] &&
      rootGetters["subscription/offer/isOfferStepValid"] &&
      rootGetters["subscription/billing/isBillingStepValid"]
    );
  },
  isSubscriptionValidForSign: (
    state: SubscriptionState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ) => {
    return (
      rootGetters["subscription/bankDetails/isBankDetailsStepValid"] &&
      rootGetters["subscription/housing/isHousingStepValid"] &&
      rootGetters[
        "subscription/contactInformation/isContactInformationStepValid"
      ] &&
      rootGetters["subscription/offer/isOfferStepValid"] &&
      rootGetters["subscription/billing/isBillingStepValid"] &&
      rootGetters["subscription/contract/isContractStepValid"]
    );
  },
};

export const mutations = {
  setSubscriptionId(state: SubscriptionState, subscriptionId: string) {
    state.subscriptionId = subscriptionId;
  },
  // Get Subscription
  setGetSubscriptionRequest(state: SubscriptionState) {
    state.getSubscriptionLoading = true;
    state.getSubscriptionError = null;
  },
  setGetSubscriptionSuccess(state: SubscriptionState) {
    state.getSubscriptionLoading = false;
  },
  setGetSubscriptionFailure(state: SubscriptionState, error: string) {
    state.getSubscriptionLoading = false;
    state.getSubscriptionError = error;
  },
  // Save Subscription
  setSaveSubscriptionRequest(state: SubscriptionState) {
    state.saveSubscriptionLoading = true;
    state.saveSubscriptionError = null;
  },
  setSaveSubscriptionSuccess(state: SubscriptionState) {
    state.saveSubscriptionLoading = false;
  },
  setSaveSubscriptionFailure(state: SubscriptionState, errors: ApiError[]) {
    state.saveSubscriptionLoading = false;
    state.saveSubscriptionError = errors;
  },
  setUpdateSubscriptionEmailRequest(state: SubscriptionState) {
    state.updateSubscriptionEmailLoading = true;
    state.updateSubscriptionEmailError = null;
  },
  setUpdateSubscriptionEmailSuccess(state: SubscriptionState) {
    state.updateSubscriptionEmailLoading = false;
  },
  setUpdateSubscriptionEmailFailure(
    state: SubscriptionState,
    errors: ApiError[]
  ) {
    state.updateSubscriptionEmailLoading = false;
    state.updateSubscriptionEmailError = errors;
  },
};

export const actions = {
  getSubscription: (context: any) => {
    context.commit("setGetSubscriptionRequest");
    return apiSubscription
      .getSubscription(context.state.subscriptionId)
      .then((result: GetSubscriptionQuery) => {
        context.commit("setGetSubscriptionSuccess");
        context.dispatch(
          "contactInformation/setContactInformation",
          result.contactInformation
        );
        context.dispatch(
          "contactInformation/setCosigner",
          result.contactInformation.cosigner
        );
        context.dispatch("housing/setHousing", result.physicalAddress);
        context.dispatch("offer/setOffer", result.productInformation);
        context.dispatch("billing/setBilling", result.productInformation);
        context.dispatch("contract/setContract", {
          termsAndConditions: result.termsAndConditions,
          solarPanelsInstalled: result.solarPanelsInstalled,
        });
        context.dispatch(
          "bankDetails/setBankDetails",
          result.paymentInformation
        );
        context.dispatch(
          "bankDetails/setBillingAddress",
          result.billingAddress
        );
        context.dispatch("contractSigning/setContractSigning", {
          state: result.contractSignatureProcess.state,
          contractName: result.contractSignatureProcess.contractName,
          yousignIframeId: null,
          signatureDate: result.contractSignatureProcess.signatureDate,
        });
      })
      .catch((error) => {
        context.commit("setGetSubscriptionFailure", error);
        notification.sendError(
          i18n.t("subscription.errors.errorLoadSubscription")
        );
      });
  },
  saveSubscription: (context: any) => {
    context.commit("setSaveSubscriptionRequest");

    const contactInformation =
      context.rootGetters[
        "subscription/contactInformation/getContactInformation"
      ];
    const physicalAddress =
      context.rootGetters["subscription/housing/getHousing"];
    const billingAddress: BillingAddress | null =
      context.rootGetters["subscription/bankDetails/getBillingAddress"];

    const productInformation = {
      ...context.rootGetters["subscription/offer/getOffer"],
      ...context.rootGetters["subscription/billing/getBilling"],
    };
    const paymentInformation =
      context.rootGetters["subscription/bankDetails/getBankDetails"];
    const termsAndConditions =
      context.rootGetters["subscription/contract/getTermsAndConditions"];
    const solarPanelsInstalled =
      context.rootGetters["subscription/contract/getSolarPanelsInstalled"];
    const subscription: SaveSubscriptionQuery = {
      contactInformation,
      physicalAddress,
      billingAddress,
      productInformation,
      paymentInformation,
      termsAndConditions,
      solarPanelsInstalled,
    };

    const saveSubscription = context.state.subscriptionId
      ? apiSubscription.updateSubscription(
          context.state.subscriptionId,
          subscription
        )
      : apiSubscription.createSubscription(subscription);

    return saveSubscription
      .then((subscriptionId) => {
        context.commit("setSaveSubscriptionSuccess");

        const successMessage = context.state.subscriptionId
          ? i18n.t("contract.successes.updateSubscriptionSuccess")
          : i18n.t("contract.successes.setSaveSubscriptionSuccess");
        notification.sendSuccess(successMessage);

        if (!context.state.subscriptionId) {
          context.commit("setSubscriptionId", subscriptionId);
        }
        return Promise.resolve(context.getters.getSubscriptionId);
      })
      .catch((response) => {
        let errorMessage;
        const errors = response.data.errors as Array<ApiError>;
        if (
          errors.some(
            (error) =>
              error.code === "CONTRACT_ALREADY_SIGNED" &&
              error.metadata?.Field === "PhysicalAddress.GsrnEANNumber"
          )
        ) {
          errorMessage = i18n.t(
            "contract.failures.contractAlreadyExistWithThisGsrnEANNumber"
          );
        } else {
          errorMessage = context.state.subscriptionId
            ? i18n.t("contract.failures.updateSubscriptionFailure")
            : i18n.t("contract.failures.setSaveSubscriptionFailure");
        }

        context.commit("setSaveSubscriptionFailure", errors);

        notification.sendError(errorMessage);
        return Promise.reject(errorMessage);
      });
  },
  updateSubscriptionEmail: (context: any, newEmail: string) => {
    context.commit("setUpdateSubscriptionEmailRequest");
    return apiSubscription
      .updateSubscriptionEmail(context.state.subscriptionId, newEmail)
      .then(() => {
        context.commit("setUpdateSubscriptionEmailSuccess");
        context.commit("contactInformation/setEmail", newEmail);
        return Promise.resolve();
      })
      .catch((response) => {
        const errors = response.data.errors as Array<ApiError>;
        context.commit("setUpdateSubscriptionEmailFailure", errors);
        return Promise.reject();
      });
  },
};

const subscription = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    contactInformation,
    housing,
    offer,
    billing,
    bankDetails,
    contract,
    emailValidation,
    contractSigning,
    contractActivation,
    gridOperator,
  },
};

export default subscription;
