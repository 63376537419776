import { httpService } from "@/api";
import { SaveSubscriptionQuery } from "@/store/subscription/type";

const getSubscription = (subscriptionId: string): Promise<any> => {
  return httpService.get(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}`
  );
};

const createSubscription = (
  subscription: SaveSubscriptionQuery
): Promise<any> => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions`,
    subscription
  );
};

const updateSubscription = (
  subscriptionId: string,
  subscription: SaveSubscriptionQuery
): Promise<any> => {
  return httpService.put(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}`,
    subscription
  );
};

const updateSubscriptionEmail = (
  subscriptionId: string,
  email: string
): Promise<any> => {
  return httpService.patch(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}/email`,
    { email }
  );
};

const emailValidationProcess = (
  subscriptionId: string,
  isRefresh = false
): Promise<any> => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}/emailValidationProcess?refresh=${isRefresh}`
  );
};

export default {
  getSubscription,
  createSubscription,
  updateSubscription,
  updateSubscriptionEmail,
  emailValidationProcess,
};
