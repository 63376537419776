import Vue from "vue";
import Vuex from "vuex";
import navigation from "@/store/navigation";
import notification from "@/store/notification";
import subscription from "@/store/subscription";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== "production",
  modules: {
    navigation,
    notification,
    subscription,
  },
  plugins: [createPersistedState({ storage: window.sessionStorage })],
});
