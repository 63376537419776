export interface ContractActivationState {
  activateContractLoading: boolean;
  downloadContractLoading: boolean;
}

export enum ContractActivationError {
  SUBSCRIPTION_NOT_FOUND = "SUBSCRIPTION_NOT_FOUND",
  RETRACTION_PERIOD_HAS_NOT_ENDED = "RETRACTION_PERIOD_HAS_NOT_ENDED",
  COMMUNICATION_FAILURE = "COMMUNICATION_FAILURE",
}
