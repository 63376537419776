import {
  Billing,
  BillingState,
  GetBillingQuery,
  PaymentType,
} from "@/store/subscription/billing/type";
import { PAYMENT_TYPES } from "@/store/subscription/billing/constant";

const state: BillingState = {
  paymentType: null,
  paymentTypes: PAYMENT_TYPES,
};

const getters = {
  getPaymentType(state: BillingState): PaymentType | null {
    return state.paymentType;
  },
  getPaymentTypes(state: BillingState): PaymentType[] {
    return state.paymentTypes;
  },
  isBillingStepValid(state: BillingState): boolean {
    return !!state.paymentType;
  },
  getBilling(state: BillingState): Billing {
    return {
      paymentType: state.paymentType,
    };
  },
};

const mutations = {
  setPaymentType(state: BillingState, paymentType: PaymentType) {
    state.paymentType = paymentType;
  },
};

const actions = {
  setBilling: (context: any, billing: GetBillingQuery) => {
    context.commit("setPaymentType", billing.paymentType);
  },
};

const billing = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default billing;
