import {
  BankDetails,
  BankDetailsState,
  BillingAddress,
  GetBankDetailsQuery,
} from "@/store/subscription/bankDetails/type";
import { stringValidators } from "@/store/subscription/validators";
import {
  DEFAULT_EMPTY_BILLING_ADDRESS,
  MAX_LENGTH_HOLDER_FAMILY_NAME,
  MAX_LENGTH_HOLDER_FIRST_NAME,
} from "@/store/subscription/bankDetails/constant";
import { StreetNumberSuffix } from "@/store/subscription/housing/type";
import { MAX_LENGTH_POST_CODE } from "@/store/subscription/housing/constants";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { mod97 } from "./helpers";

const state: BankDetailsState = {
  holderFirstName: "",
  holderFamilyName: "",
  holderCompanyName: "",
  isBillingAddressSameAsPhysical: true,
  billingAddress: DEFAULT_EMPTY_BILLING_ADDRESS,
  iban: "",
  bic: "",
};

const getters = {
  getHolderFirstName(state: BankDetailsState): string {
    return state.holderFirstName;
  },
  isHolderFirstNameEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.holderFirstName);
  },
  isHolderFirstNameBadFormat(state: BankDetailsState): boolean {
    return !stringValidators.isName(
      state.holderFirstName,
      MAX_LENGTH_HOLDER_FIRST_NAME
    );
  },
  isHolderFirstNameValid(state: BankDetailsState, getters: any): boolean {
    const isEmpty = getters.isHolderFirstNameEmpty;
    const isBadFormat = getters.isHolderFirstNameBadFormat;
    return !isEmpty && !isBadFormat;
  },
  getHolderFirstNameErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isHolderFirstNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isHolderFirstNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getHolderFamilyName(state: BankDetailsState): string {
    return state.holderFamilyName;
  },
  isHolderFamilyNameEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.holderFamilyName);
  },
  isHolderFamilyNameBadFormat(state: BankDetailsState): boolean {
    return !stringValidators.isName(
      state.holderFamilyName,
      MAX_LENGTH_HOLDER_FAMILY_NAME
    );
  },
  isHolderFamilyNameValid(state: BankDetailsState, getters: any): boolean {
    const isEmpty = getters.isHolderFamilyNameEmpty;
    const isBadFormat = getters.isHolderFamilyNameBadFormat;
    return !isEmpty && !isBadFormat;
  },
  getHolderFamilyNameErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isHolderFamilyNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isHolderFamilyNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getHolderCompanyName(state: BankDetailsState): string {
    return state.holderCompanyName;
  },
  isHolderCompanyNameEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.holderCompanyName);
  },
  isHolderCompanyNameValid(state: BankDetailsState, getters: any): boolean {
    return !getters.isHolderCompanyNameEmpty;
  },
  getHolderCompanyNameErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isHolderCompanyNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getIsBillingAddressSameAsPhysical(state: BankDetailsState): boolean {
    return state.isBillingAddressSameAsPhysical;
  },

  getStreetNumber(state: BankDetailsState): string {
    return state.billingAddress.streetNumber;
  },
  isStreetNumberBadFormat(state: BankDetailsState): boolean {
    return (
      state.billingAddress.streetNumber === "0" ||
      isNaN(Number(state.billingAddress.streetNumber))
    );
  },
  isStreetNumberValid(state: BankDetailsState, getters: any): boolean {
    return !getters.isStreetNumberBadFormat;
  },
  getStreetNumberErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isStreetNumberBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getStreetNumberSuffix(state: BankDetailsState): StreetNumberSuffix | null {
    return state.billingAddress.streetNumberSuffix;
  },
  getStreetNumberSuffixes(
    state: BankDetailsState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ): Array<StreetNumberSuffix> {
    return rootGetters["subscription/housing/getStreetNumberSuffixes"];
  },

  getStreet(state: BankDetailsState): string {
    return state.billingAddress.street;
  },
  isStreetEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.billingAddress.street);
  },
  isStreetValid(state: BankDetailsState, getters: any): boolean {
    return !getters.isStreetEmpty;
  },
  getStreetErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isStreetEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getPostCode(state: BankDetailsState): string {
    return state.billingAddress.postCode;
  },
  isPostCodeEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.billingAddress.postCode);
  },
  isPostCodeBadFormat(state: BankDetailsState): boolean {
    return !stringValidators.isNumberWithCorrectLength(
      state.billingAddress.postCode,
      MAX_LENGTH_POST_CODE
    );
  },
  isPostCodeValid(state: BankDetailsState, getters: any): boolean {
    return !getters.isPostCodeEmpty && !getters.isPostCodeBadFormat;
  },
  getPostCodeErrors(
    state: BankDetailsState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isPostCodeEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isPostCodeBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getCity(state: BankDetailsState): string {
    return state.billingAddress.city;
  },
  isCityEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.billingAddress.city);
  },
  isCityValid(state: BankDetailsState, getters: any): boolean {
    return !getters.isCityEmpty;
  },
  getCityErrors(state: BankDetailsState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isCityEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getBillingAddress(
    state: BankDetailsState,
    getters: any
  ): BillingAddress | null {
    return getters.getIsBillingAddressSameAsPhysical
      ? null
      : state.billingAddress;
  },

  getIban(state: BankDetailsState): string {
    return state.iban;
  },
  isIbanEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.iban);
  },
  isNotFrenchIban(state: BankDetailsState): boolean {
    return !stringValidators.isFrenchIban(state.iban);
  },
  cantExist(state: BankDetailsState): boolean {
    const iban = String(state.iban)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

    // check syntax and length
    if (!code) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
      return String(letter.charCodeAt(0) - 55);
    });

    return mod97(digits) !== 1;
  },
  isIbanValid(state: BankDetailsState, getters: any): boolean {
    const isEmpty = getters.isIbanEmpty;
    const isNotFrenchIban = getters.isNotFrenchIban;
    const cantExist = getters.cantExist;
    return !isEmpty && !isNotFrenchIban && !cantExist;
  },
  getIbanErrors(state: BankDetailsState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isIbanEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isNotFrenchIban) {
      errors.push(i18n.t("common.formError.iBanHasBadFormat"));
    } else if (getters.cantExist) {
      errors.push(i18n.t("common.formError.iBanIsNotValid"));
    }
    return errors;
  },

  getBic(state: BankDetailsState): string {
    return state.bic;
  },
  isBicEmpty(state: BankDetailsState): boolean {
    return stringValidators.isEmpty(state.bic);
  },
  isBicBadFormat(state: BankDetailsState): boolean {
    return !stringValidators.isBic(state.bic);
  },
  isBicValid(state: BankDetailsState, getters: any): boolean {
    const isEmpty = getters.isBicEmpty;
    const isBadFormat = getters.isBicBadFormat;
    return !isEmpty && !isBadFormat;
  },
  getBicErrors(state: BankDetailsState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isBicEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isBicBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getBankDetails(
    state: BankDetailsState,
    getters: any,
    rootState: any,
    rootGetters: any
  ): BankDetails {
    const isIndividualClient =
      rootGetters["subscription/contactInformation/isIndividualClient"];
    const bankDetails: BankDetails = {
      iban: state.iban.replace(/\s/g, ""),
      bic: state.bic,
    };

    if (isIndividualClient) {
      bankDetails.holderFirstName = state.holderFirstName;
      bankDetails.holderFamilyName = state.holderFamilyName;
    } else {
      bankDetails.holderCompanyName = state.holderCompanyName;
    }
    return bankDetails;
  },

  isBankDetailsStepValid(
    state: BankDetailsState,
    getters: any,
    rootState: any,
    rootGetters: any
  ): boolean {
    const isIndividualClient =
      rootGetters["subscription/contactInformation/isIndividualClient"];

    const checkIfAccountInformationValid = (): boolean => {
      return getters.isBicValid && getters.isIbanValid;
    };

    const checkIfHolderInformationValid = (): boolean => {
      if (isIndividualClient) {
        return (
          getters.isHolderFirstNameValid && getters.isHolderFamilyNameValid
        );
      }
      return getters.isHolderCompanyNameValid;
    };

    const checkIfAddressInformationValid = (): boolean => {
      if (getters.getIsBillingAddressSameAsPhysical) {
        return true;
      }
      return (
        getters.isStreetNumberValid &&
        getters.isStreetValid &&
        getters.isPostCodeValid &&
        getters.isCityValid
      );
    };

    const isBankDetailsStepValid =
      checkIfAccountInformationValid() &&
      checkIfHolderInformationValid() &&
      checkIfAddressInformationValid();

    return isBankDetailsStepValid;
  },
};

const mutations = {
  setHolderFirstName(state: BankDetailsState, holderFirstName: string) {
    state.holderFirstName = holderFirstName;
  },
  setHolderFamilyName(state: BankDetailsState, holderFamilyName: string) {
    state.holderFamilyName = holderFamilyName;
  },
  setHolderCompanyName(state: BankDetailsState, holderCompanyName: string) {
    state.holderCompanyName = holderCompanyName;
  },
  setIsBillingAddressSameAsPhysical(
    state: BankDetailsState,
    isBillingAddressSameAsPhysical: boolean
  ) {
    state.isBillingAddressSameAsPhysical = isBillingAddressSameAsPhysical;
  },
  setBillingAddress(
    state: BankDetailsState,
    billingAddress: BillingAddress | null
  ) {
    const newBillingAddress = billingAddress
      ? billingAddress
      : DEFAULT_EMPTY_BILLING_ADDRESS;

    state.billingAddress = { ...newBillingAddress };
  },
  setStreetNumber: (state: BankDetailsState, streetNumber: string) => {
    state.billingAddress.streetNumber = streetNumber;
  },
  setStreetNumberSuffix: (
    state: BankDetailsState,
    streetNumberSuffix: StreetNumberSuffix
  ) => {
    state.billingAddress.streetNumberSuffix = streetNumberSuffix;
  },
  setStreet: (state: BankDetailsState, street: string) => {
    state.billingAddress.street = street;
  },
  setPostCode: (state: BankDetailsState, postCode: string) => {
    state.billingAddress.postCode = postCode;
  },
  setCity: (state: BankDetailsState, city: string) => {
    state.billingAddress.city = city;
  },
  setIban(state: BankDetailsState, iban: string) {
    state.iban = iban;
  },
  setBic(state: BankDetailsState, bic: string) {
    state.bic = bic;
  },
};

const actions = {
  setBankDetails: (context: any, bankDetails: GetBankDetailsQuery) => {
    context.commit("setHolderFirstName", bankDetails.holderFirstName);
    context.commit("setHolderFamilyName", bankDetails.holderFamilyName);
    context.commit("setHolderCompanyName", bankDetails.holderCompanyName);
    context.commit("setIban", bankDetails.iban);
    context.commit("setBic", bankDetails.bic);
  },
  setIsBillingAddressSameAsPhysical: (
    context: any,
    isBillingAddressSameAsPhysical: boolean
  ) => {
    context.commit(
      "setIsBillingAddressSameAsPhysical",
      isBillingAddressSameAsPhysical
    );
    context.commit("setBillingAddress", DEFAULT_EMPTY_BILLING_ADDRESS);
  },
  setBillingAddress: (context: any, billingAddress: BillingAddress | null) => {
    const isBillingAddressSameAsPhysical = billingAddress === null;

    context.commit(
      "setIsBillingAddressSameAsPhysical",
      isBillingAddressSameAsPhysical
    );
    context.commit("setBillingAddress", billingAddress);
  },
};

const billing = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default billing;
