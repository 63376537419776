import {
  ContractClientInformation,
  ContractOfferInformation,
  ContractPaymentInformation,
  ContractSigningInformation,
  ContractState,
  ContractSummary,
} from "@/store/subscription/contract/type";
import { Housing, ContractedPower } from "@/store/subscription/housing/type";
import { Offer, BatteryCapacity } from "@/store/subscription/offer/type";
import { Billing } from "@/store/subscription/billing/type";
import i18n from "@/i18n";
import {
  getShortBillingAddress,
  getShortPhysicalAddress,
  getStringOrDefault,
} from "@/store/subscription/contract/helper";
import { BillingAddress } from "@/store/subscription/bankDetails/type";
import { ContractSigning } from "@/store/subscription/contractSigning/type";

const state: ContractState = {
  termsAndConditions: false,
  solarPanelsInstalled: false,
};

const getters = {
  getTermsAndConditions(state: ContractState): boolean {
    return state.termsAndConditions;
  },
  getSolarPanelsInstalled(state: ContractState): boolean {
    return state.solarPanelsInstalled;
  },
  isContractStepValid(
    state: ContractState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ): boolean {
    return (
      getters.getTermsAndConditions &&
      getters.getSolarPanelsInstalled &&
      rootGetters["subscription/emailValidation/isValidatedEmail"]
    );
  },
  getContractSummary(
    state: ContractState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ): ContractSummary {
    const contactInformation =
      rootGetters["subscription/contactInformation/getContactInformation"];
    const physicalAddress: Housing =
      rootGetters["subscription/housing/getHousing"];
    const billingAddress: BillingAddress | null =
      rootGetters["subscription/bankDetails/getBillingAddress"];
    const productInformation: Offer & Billing = {
      ...rootGetters["subscription/offer/getOffer"],
      ...rootGetters["subscription/billing/getBilling"],
    };
    const contractSigning: ContractSigning =
      rootGetters["subscription/contractSigning/getContractSigning"];

    const shortBillingAddress =
      billingAddress === null
        ? getShortPhysicalAddress(physicalAddress)
        : getShortBillingAddress(billingAddress);

    const gridOperatorName =
      rootGetters["subscription/housing/getGridOperatorName"];

    const clientInformation: ContractClientInformation = {
      firstName: getStringOrDefault(contactInformation.firstName),
      familyName: getStringOrDefault(contactInformation.familyName),
      companyName: getStringOrDefault(contactInformation.companyName),
      email: getStringOrDefault(contactInformation.email),
      physicalAddress: getShortPhysicalAddress(physicalAddress),
      billingAddress: shortBillingAddress,
      pdlNumber: getStringOrDefault(physicalAddress.gsrnEANNumber),
      gridOperator: getStringOrDefault(gridOperatorName),
    };

    const selectedOfferType = productInformation.productType
      ? i18n.t(`contract.offerTypes['${productInformation.productType}']`)
      : null;
    const selectedContractedPower = physicalAddress.contractedPower
      ? `${
          isNaN(physicalAddress.contractedPower)
            ? ContractedPower[physicalAddress.contractedPower]
            : physicalAddress.contractedPower
        } kVa`
      : null;
    const selectedBatteryCapacity = productInformation.batteryCapacity
      ? `${
          isNaN(productInformation.batteryCapacity)
            ? BatteryCapacity[productInformation.batteryCapacity]
            : productInformation.batteryCapacity
        } kWh`
      : null;
    const offerInformation: ContractOfferInformation = {
      offerType: getStringOrDefault(selectedOfferType),
      tariffType: i18n.t("contract.selectedTariffType"),
      contractedPower: getStringOrDefault(selectedContractedPower),
      batteryCapacity: getStringOrDefault(selectedBatteryCapacity),
    };

    const selectedBillingType = productInformation.paymentType
      ? i18n.t(`billing.paymentType['${productInformation.paymentType}'].name`)
      : null;
    const paymentInformation: ContractPaymentInformation = {
      billingType: getStringOrDefault(selectedBillingType),
      contractDeliveryType: i18n.t("contract.selectedContractDeliveryType"),
      paymentType: i18n.t("contract.selectedPaymentType"),
    };

    const contractSigningInformation: ContractSigningInformation = {
      yousignIframeId: contractSigning.yousignIframeId,
      contractName: contractSigning.contractName,
      contractSigningStatus: contractSigning.contractSigningStatus,
      activationDate: contractSigning.activationDate,
      signatureDate: contractSigning.signatureDate,
    };

    return {
      clientInformation,
      offerInformation,
      paymentInformation,
      contractSigningInformation,
    };
  },
};

const mutations = {
  setTermsAndConditions(state: ContractState, termsAndConditions: boolean) {
    state.termsAndConditions = termsAndConditions;
  },
  setSolarPanelsInstalled(state: ContractState, solarPanelsInstalled: boolean) {
    state.solarPanelsInstalled = solarPanelsInstalled;
  },
};

const actions = {
  setContract: (
    context: any,
    payload: {
      termsAndConditions: boolean;
      solarPanelsInstalled: boolean;
    }
  ) => {
    context.commit("setTermsAndConditions", payload.termsAndConditions);
    context.commit("setSolarPanelsInstalled", payload.solarPanelsInstalled);
  },
};

const contract = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default contract;
