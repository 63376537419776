<template>
  <img
    alt="Domuneo"
    class="logo--domuneo"
    src="@/assets/images/tenants/domuneo/logoWithText.svg"
  />
</template>

<script>
export default {
  name: "domuneoLogo",
};
</script>

<style lang="scss" scoped>
.logo--domuneo {
  width: 170px;
}
</style>
