<template>
  <div id="formSubscriptionLayout">
    <MainLayout
      :loading="isGetSubscriptionLoading"
      :with-mobile-step-header="true"
    >
      <template v-slot:sidebar>
        <SubscriptionStepsNavigation />
      </template>
      <template v-slot:content>
        <router-view />
      </template>
    </MainLayout>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SubscriptionStepsNavigation from "@/views/layout/formSubscriptionLayout/SubscriptionStepsNavigation";
import MainLayout from "@/components/layout/MainLayout";

export default {
  name: "formSubscriptionLayout",
  components: {
    MainLayout,
    SubscriptionStepsNavigation,
  },
  created() {
    if (this.isRegisteredSubscription) {
      this.getSubscription();
    }
    this.getBatteryPlans();
  },
  computed: {
    ...mapGetters("subscription", [
      "isRegisteredSubscription",
      "isGetSubscriptionLoading",
    ]),
  },
  methods: {
    ...mapActions("subscription", ["getSubscription"]),
    ...mapActions("subscription/offer", ["getBatteryPlans"]),
  },
};
</script>

<style lang="scss" scoped></style>
