<template>
  <div
    id="mainHeader"
    :class="{
      'mainHeader--with-back-button': withBackButton,
      'mainHeader--with-help-button': withHelpButton,
    }"
  >
    <span
      v-if="withBackButton"
      class="mainHeader__back-button"
      cy-id="backButton"
      @click="$emit('on-go-back')"
    >
      <b-icon icon="arrow-left" size="is-medium" type="is-primary"></b-icon>
    </span>
    <span :class="['mainHeader__title']">{{ mainHeaderTitle }}</span>
    <span
      v-if="withHelpButton"
      class="mainHeader__help-button"
      cy-id="helpButton"
      @click="$emit('on-help')"
    >
      <b-icon
        icon="account-question-outline"
        size="is-medium"
        type="is-primary"
      ></b-icon>
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainHeader",
  props: {
    withBackButton: {
      type: Boolean,
      default: false,
    },
    withHelpButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("navigation", {
      mainHeaderTitle: "getMainHeaderTitle",
    }),
  },
};
</script>

<style lang="scss" scoped>
$width-dekstop-button: 100px;
$width-mobile-button: 60px;

#mainHeader {
  position: relative;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row: 1;
  border-bottom: 1px solid #fff;
  background: $lightGrey;
  z-index: 3;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
}

@include media("<tablet") {
  #mainHeader {
    grid-column-start: 1;
    grid-column-end: 2 span;
    justify-content: center;
    background: $greyBackground;
  }
}

#mainHeader.mainHeader--with-back-button {
  grid-template-columns: $width-dekstop-button 1fr;
}

@include media("<tablet") {
  #mainHeader.mainHeader--with-back-button {
    grid-template-columns: $width-mobile-button 1fr;
  }
}

#mainHeader.mainHeader--with-help-button {
  grid-template-columns: 1fr $width-dekstop-button;
}

@include media("<tablet") {
  #mainHeader.mainHeader--with-help-button {
    grid-template-columns: 1fr $width-mobile-button;
  }
}

#mainHeader.mainHeader--with-help-button.mainHeader--with-back-button {
  grid-template-columns: $width-dekstop-button 1fr $width-dekstop-button;
}

@include media("<tablet") {
  #mainHeader.mainHeader--with-help-button.mainHeader--with-back-button {
    grid-template-columns: $width-mobile-button 1fr $width-mobile-button;
  }
}

.mainHeader__title {
  font-size: 1.25rem;
  font-weight: bold;
  text-transform: uppercase;
  padding-left: 2rem;
}

.mainHeader--with-back-button {
  .mainHeader__title {
    padding: 0;
  }
}

@include media("<tablet") {
  .mainHeader__title {
    padding-left: 0;
    justify-self: center;
  }
}

.mainHeader__back-button {
  display: flex;
  align-items: center;
  justify-self: center;
  cursor: pointer;
  padding: 0 2rem;
  height: 100%;
}

.mainHeader__help-button {
  justify-self: center;
  cursor: pointer;
}

@include media("<tablet") {
  .mainHeader__help-button {
    position: relative;
    top: 2px;
  }
}
</style>
