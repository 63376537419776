import { Cosigner } from "./type";

export const MAX_LENGTH_FIRST_NAME = 50;
export const MAX_LENGTH_FAMILY_NAME = 50;
export const MAX_LENGTH_EMAIL = 100;
export const MAX_LENGTH_MOBILE_PHONE = 10;
export const MAX_LENGTH_COMPANY_IDENTIFICATION_NUMBER = 14;
export const DEFAULT_EMPTY_COSIGNER: Cosigner = {
  prefix: null,
  familyName: "",
  firstName: "",
};
