import {
  ConsumptionInformationType,
  ContractedPower,
  ErrorValidationGsrnEan,
  GetHousingQuery,
  GridOperator,
  GridOperatorEnum,
  GridOperatorStatus,
  Housing,
  HousingState,
  StreetNumberSuffix,
} from "@/store/subscription/housing/type";
import {
  MAX_LENGTH_GSRN_EAN_NUMBER_ENEDIS,
  MAX_LENGTH_GSRN_EAN_NUMBER_ES,
  MAX_LENGTH_POST_CODE,
} from "@/store/subscription/housing/constants";

import apiGsrnEanValidation from "@/api/gsrnEanValidation";

import { stringValidators } from "@/store/subscription/validators";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { ApiErrorResponse } from "@/api/type";

export const state: HousingState = {
  gridOperatorId: null,
  contractedPowers: [
    ContractedPower.SIX,
    ContractedPower.NINE,
    ContractedPower.TWELVE,
    ContractedPower.FIFTEEN,
    ContractedPower.EIGHTEEN,
    ContractedPower.TWENTY_FOUR,
    ContractedPower.THIRTY,
    ContractedPower.THIRTY_SIX,
  ],
  contractedPower: null,
  gsrnEANNumber: "",
  streetNumber: "",
  streetNumberSuffixes: [
    StreetNumberSuffix.BIS,
    StreetNumberSuffix.TER,
    StreetNumberSuffix.QUATER,
  ],
  streetNumberSuffix: null,
  street: "",
  postCode: "",
  city: "",
  consumptionInformationType: ConsumptionInformationType.EURO,
  consumptionInformationEuros: "",
  consumptionInformationKwh: "",
  loadingValidationGsrnEan: false,
  errorValidationGsrnEan: null,
  successValidationGsrnEan: false,
};

export const getters = {
  getGridOperator(
    state: HousingState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ): GridOperator | null {
    const gridOperator: GridOperator | undefined = rootGetters[
      "subscription/gridOperator/getGridOperators"
    ].find(
      (gridOperator: GridOperator) => gridOperator.id === state.gridOperatorId
    );
    return gridOperator || null;
  },
  getGridOperatorName(state: HousingState, getters: any): string {
    const gridOperator: GridOperator | null = getters.getGridOperator;
    return gridOperator ? gridOperator.name : "";
  },
  getGridOperatorEnum(state: HousingState, getters: any): string {
    const gridOperator: GridOperator | null = getters.getGridOperator;
    return gridOperator ? gridOperator.gridOperatorEnum : "";
  },
  getGridOperatorStatus: (
    state: HousingState,
    getters: any
  ): GridOperatorStatus => {
    const gridOperator: GridOperator | null = getters.getGridOperator;
    return gridOperator ? gridOperator.status : GridOperatorStatus.UNMANAGED;
  },
  getGridOperatorId(state: HousingState): string | null {
    return state.gridOperatorId;
  },
  isGridOperatorValid(state: HousingState, getters: any): boolean {
    const isGridOperatorManaged =
      getters.getGridOperatorStatus === GridOperatorStatus.MANAGED;
    return isGridOperatorManaged;
  },

  getContractedPowers(state: HousingState): Array<ContractedPower> {
    return state.contractedPowers;
  },
  getContractedPower(state: HousingState): ContractedPower | null {
    return state.contractedPower;
  },
  getContractedPowerErrors(
    state: HousingState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isContractedPowerEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },
  isContractedPowerEmpty(state: HousingState): boolean {
    return stringValidators.isEmpty(state.contractedPower as string | null);
  },
  isContractedPowerValid(state: HousingState, getters: any): boolean {
    return !getters.isContractedPowerEmpty;
  },

  getGsrnEANNumber(state: HousingState): string {
    return state.gsrnEANNumber;
  },
  isGsrnEANNumberEmpty(state: HousingState): boolean {
    return stringValidators.isEmpty(state.gsrnEANNumber);
  },
  isGsrnEANNumberBadFormat(state: HousingState, getters: any): boolean {
    return getters.getGridOperatorEnum === GridOperatorEnum.ENEDIS
      ? !stringValidators.isNumberWithCorrectLength(
          state.gsrnEANNumber,
          MAX_LENGTH_GSRN_EAN_NUMBER_ENEDIS
        )
      : state.gsrnEANNumber.length < MAX_LENGTH_GSRN_EAN_NUMBER_ES;
  },
  isGsrnEANNumberValid(state: HousingState, getters: any): boolean {
    return (
      !getters.isGsrnEANNumberEmpty &&
      !getters.isGsrnEANNumberBadFormat &&
      state.successValidationGsrnEan
    );
  },
  getGsrnEANNumberErrors(
    state: HousingState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isGsrnEANNumberEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isGsrnEANNumberBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    } else if (state.errorValidationGsrnEan) {
      errors.push(
        i18n.t("housing.errors.gsrnEANNumber." + state.errorValidationGsrnEan, {
          phoneNumber: i18n.t(
            "common.support.phoneNumber." + process.env.VUE_APP_TENANT
          ),
          grdName: getters.getGridOperatorName,
        })
      );
    }
    return errors;
  },
  getLoadingValidationGsrnEan(state: HousingState) {
    return state.loadingValidationGsrnEan;
  },
  getErrorValidationGsrnEan(state: HousingState) {
    return state.errorValidationGsrnEan;
  },
  getStreetNumber(state: HousingState): string {
    return state.streetNumber;
  },
  isStreetNumberBadFormat(state: HousingState): boolean {
    return state.streetNumber === "0" || isNaN(Number(state.streetNumber));
  },
  isStreetNumberValid(state: HousingState, getters: any): boolean {
    return !getters.isStreetNumberBadFormat;
  },
  getStreetNumberErrors(
    state: HousingState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isStreetNumberBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getStreetNumberSuffix(state: HousingState): StreetNumberSuffix | null {
    return state.streetNumberSuffix;
  },
  getStreetNumberSuffixes(state: HousingState): Array<StreetNumberSuffix> {
    return state.streetNumberSuffixes;
  },

  getStreet(state: HousingState): string {
    return state.street;
  },
  isStreetEmpty(state: HousingState): boolean {
    return stringValidators.isEmpty(state.street);
  },
  isStreetValid(state: HousingState, getters: any): boolean {
    return !getters.isStreetEmpty;
  },
  getStreetErrors(state: HousingState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isStreetEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getPostCode(state: HousingState): string {
    return state.postCode;
  },
  isPostCodeEmpty(state: HousingState): boolean {
    return stringValidators.isEmpty(state.postCode);
  },
  isPostCodeBadFormat(state: HousingState): boolean {
    return !stringValidators.isNumberWithCorrectLength(
      state.postCode,
      MAX_LENGTH_POST_CODE
    );
  },
  isPostCodeValid(state: HousingState, getters: any): boolean {
    return !getters.isPostCodeEmpty && !getters.isPostCodeBadFormat;
  },
  getPostCodeErrors(state: HousingState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isPostCodeEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isPostCodeBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getCity(state: HousingState): string {
    return state.city;
  },
  isCityEmpty(state: HousingState): boolean {
    return stringValidators.isEmpty(state.city);
  },
  isCityValid(state: HousingState, getters: any): boolean {
    return !getters.isCityEmpty;
  },
  getCityErrors(state: HousingState, getters: any): Array<TranslateResult> {
    const errors = [];
    if (getters.isCityEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getConsumptionInformationType(state: HousingState): string {
    return state.consumptionInformationType;
  },

  getConsumptionInformationEuros(state: HousingState): string {
    return state.consumptionInformationEuros;
  },
  isConsumptionInformationEurosEmpty(state: HousingState): boolean {
    return (
      stringValidators.isEmpty(state.consumptionInformationEuros) ||
      state.consumptionInformationEuros === "0"
    );
  },
  isConsumptionInformationEurosValid(
    state: HousingState,
    getters: any
  ): boolean {
    return !getters.isConsumptionInformationEurosEmpty;
  },
  getConsumptionInformationEurosErrors(
    state: HousingState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isConsumptionInformationEurosEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getConsumptionInformationKwh(state: HousingState): string {
    return state.consumptionInformationKwh;
  },
  isConsumptionInformationKwhEmpty(state: HousingState): boolean {
    return (
      stringValidators.isEmpty(state.consumptionInformationKwh) ||
      state.consumptionInformationKwh === "0"
    );
  },
  isConsumptionInformationKwhValid(state: HousingState, getters: any): boolean {
    return !getters.isConsumptionInformationKwhEmpty;
  },
  getConsumptionInformationKwhErrors(
    state: HousingState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isConsumptionInformationKwhEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getHousing(state: HousingState): Housing {
    const housing = {
      gridOperatorId: state.gridOperatorId,
      contractedPower: state.contractedPower,
      gsrnEANNumber: state.gsrnEANNumber,
      streetNumber: state.streetNumber,
      streetNumberSuffix: state.streetNumberSuffix,
      street: state.street,
      postCode: state.postCode,
      city: state.city,
    };

    if (state.consumptionInformationType === ConsumptionInformationType.WATT) {
      const consumptionInformationKwh =
        state.consumptionInformationKwh === null
          ? state.consumptionInformationKwh
          : Number(state.consumptionInformationKwh);

      return {
        ...housing,
        consumptionInformationKwh,
      };
    } else {
      const consumptionInformationEuros =
        state.consumptionInformationEuros === null
          ? state.consumptionInformationEuros
          : Number(state.consumptionInformationEuros);

      return {
        ...housing,
        consumptionInformationEuros,
      };
    }
  },
  isHousingStepValid(state: HousingState, getters: any): boolean {
    return (
      getters.isGridOperatorValid &&
      (getters.isGsrnEANNumberValid ||
        [
          ErrorValidationGsrnEan.SERVER_ENERGYCOM_RESPOND_INVALID,
          ErrorValidationGsrnEan.SERVER_ENERGYCOM_NOT_RESPOND,
          ErrorValidationGsrnEan.UNKNOWN_ERROR,
        ].includes(getters.getErrorValidationGsrnEan)) &&
      getters.isContractedPowerValid &&
      getters.isStreetNumberValid &&
      getters.isStreetValid &&
      getters.isPostCodeValid &&
      getters.isCityValid &&
      ((getters.getConsumptionInformationType ===
        ConsumptionInformationType.EURO &&
        getters.isConsumptionInformationEurosValid) ||
        (getters.getConsumptionInformationType ===
          ConsumptionInformationType.WATT &&
          getters.isConsumptionInformationKwhValid))
    );
  },
};

export const mutations = {
  setGridOperatorId: (state: HousingState, gridOperatorId: string | null) => {
    state.gridOperatorId = gridOperatorId;
  },
  setContractedPower: (
    state: HousingState,
    contractedPower: ContractedPower
  ) => {
    state.contractedPower = contractedPower;
  },
  setGsrnEANNumber: (state: HousingState, gsrnEANNumber: string) => {
    state.gsrnEANNumber = gsrnEANNumber;
  },
  setStreetNumber: (state: HousingState, streetNumber: string) => {
    state.streetNumber = streetNumber;
  },
  setStreetNumberSuffix: (
    state: HousingState,
    streetNumberSuffix: StreetNumberSuffix
  ) => {
    state.streetNumberSuffix = streetNumberSuffix;
  },
  setStreet: (state: HousingState, street: string) => {
    state.street = street;
  },
  setPostCode: (state: HousingState, postCode: string) => {
    state.postCode = postCode;
  },
  setCity: (state: HousingState, city: string) => {
    state.city = city;
  },
  setConsumptionInformationType(
    state: HousingState,
    consumptionInformationType: ConsumptionInformationType
  ) {
    state.consumptionInformationType = consumptionInformationType;
  },
  setConsumptionInformationEuros(
    state: HousingState,
    consumptionInformationEuros: string
  ) {
    state.consumptionInformationEuros = consumptionInformationEuros;
  },
  setConsumptionInformationKwh(
    state: HousingState,
    consumptionInformationKwh: string
  ) {
    state.consumptionInformationKwh = consumptionInformationKwh;
  },
  setLoadingValidationGsrnEan(
    state: HousingState,
    loadingValidationGsrnEan: boolean
  ) {
    state.loadingValidationGsrnEan = loadingValidationGsrnEan;
  },
  setErrorValidationGsrnEan(
    state: HousingState,
    errorValidationGsrnEan: ErrorValidationGsrnEan | null
  ) {
    state.errorValidationGsrnEan = errorValidationGsrnEan;
  },
  clearErrorValidationGsrnEan(state: HousingState) {
    state.errorValidationGsrnEan = null;
  },
  setSuccessValidationGsrnEan(
    state: HousingState,
    successValidationGsrnEan: boolean
  ) {
    state.successValidationGsrnEan = successValidationGsrnEan;
  },
};

export const actions = {
  setHousing: (context: any, housing: GetHousingQuery) => {
    context.commit("setGridOperatorId", housing.gridOperatorId);
    context.commit("setContractedPower", housing.contractedPower);
    context.commit("setGsrnEANNumber", housing.gsrnEANNumber);
    context.commit("setStreetNumber", housing.streetNumber);
    context.commit("setStreetNumberSuffix", housing.streetNumberSuffix);
    context.commit("setStreet", housing.street);
    context.commit("setPostCode", housing.postCode);
    context.commit("setCity", housing.city);
    context.commit("setSuccessValidationGsrnEan", true);

    if (housing.consumptionInformationKwh) {
      context.commit(
        "setConsumptionInformationKwh",
        housing.consumptionInformationKwh
      );
      context.commit(
        "setConsumptionInformationType",
        ConsumptionInformationType.WATT
      );
    } else {
      context.commit(
        "setConsumptionInformationEuros",
        housing.consumptionInformationEuros
      );
      context.commit(
        "setConsumptionInformationType",
        ConsumptionInformationType.EURO
      );
    }
  },
  checkGsrnEan: (context: any, gsrnEanNumber: string) => {
    if (!context.state.loadingValidationGsrnEan) {
      context.commit("setLoadingValidationGsrnEan", true);
      context.commit("setErrorValidationGsrnEan", null);
      context.commit("setGsrnEANNumber", gsrnEanNumber);
      apiGsrnEanValidation
        .validateGsrnEan(gsrnEanNumber, context.getters.getGridOperatorEnum)
        .then(() => {
          context.commit("setSuccessValidationGsrnEan", true);
        })
        .catch((response: ApiErrorResponse) => {
          if (!response || !response.status) {
            context.commit(
              "setErrorValidationGsrnEan",
              ErrorValidationGsrnEan.UNKNOWN_ERROR
            );
            return;
          }

          if (response.status === 400) {
            context.commit(
              "setErrorValidationGsrnEan",
              ErrorValidationGsrnEan.FORMAT_INVALID
            );
            return;
          }

          if (response.status === 404) {
            context.commit(
              "setErrorValidationGsrnEan",
              ErrorValidationGsrnEan.GSRNEAN_NOTFOUND
            );
            return;
          }

          if (response.status === 502) {
            context.commit(
              "setErrorValidationGsrnEan",
              ErrorValidationGsrnEan.SERVER_ENERGYCOM_RESPOND_INVALID
            );
            return;
          }

          if (response.status === 503) {
            context.commit(
              "setErrorValidationGsrnEan",
              ErrorValidationGsrnEan.SERVER_ENERGYCOM_NOT_RESPOND
            );
            return;
          }

          context.commit(
            "setErrorValidationGsrnEan",
            ErrorValidationGsrnEan.UNKNOWN_ERROR
          );
          return;
        })
        .finally(() => {
          context.commit("setLoadingValidationGsrnEan", false);
        });
    }
  },
};

const housing = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default housing;
