import { ApiErrorResponse } from "@/api/type";
import i18n from "@/i18n";
import { ContractActivationError } from "@/store/subscription/contractActivation/type";

export const getActivateContractErrorMessage = (
  errorResponse: ApiErrorResponse
) => {
  const errorCode =
    errorResponse &&
    errorResponse.errors &&
    errorResponse.errors[0] &&
    errorResponse.errors[0].code;
  const isHandledError = Object.values(ContractActivationError).includes(
    errorCode as ContractActivationError
  );
  if (isHandledError) {
    return i18n.t(`contractActivation.errors['${errorCode}']`);
  }
  return i18n.t("contractActivation.errors.UNKNOWN_ERROR");
};
