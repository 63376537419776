import { ContractActivationState } from "@/store/subscription/contractActivation/type";
import apiContractActivation from "@/api/contractActivation";
import i18n from "@/i18n";
import notification from "@/store/notification/helper";
import { AxiosResponse } from "axios";
import { getActivateContractErrorMessage } from "@/store/subscription/contractActivation/helper";
import { ApiErrorResponse } from "@/api/type";
import { ContractSigningStatus } from "@/store/subscription/contractSigning/type";
import store from "@/store";
import { RouteName } from "@/store/navigation/type";
import router from "@/router";

const state: ContractActivationState = {
  activateContractLoading: false,
  downloadContractLoading: false,
};

const getters = {
  getActivateContractLoading(state: ContractActivationState): boolean {
    return state.activateContractLoading;
  },
  getDownloadContractLoading(state: ContractActivationState): boolean {
    return state.downloadContractLoading;
  },
  canReachContractActivationPage(
    state: ContractActivationState,
    getters: any,
    rootState: any,
    rootGetters: { [x: string]: any }
  ): boolean {
    const isGetSubscriptionError =
      rootGetters["subscription/isGetSubscriptionError"];
    const contractSigningStatus =
      rootGetters["subscription/contractSigning/getContractSigningStatus"];
    const isContractSigned =
      contractSigningStatus === ContractSigningStatus.SIGNED ||
      contractSigningStatus === ContractSigningStatus.PENDING_ACTIVATION ||
      contractSigningStatus === ContractSigningStatus.ACTIVATION_REQUESTED ||
      contractSigningStatus ===
        ContractSigningStatus.GRID_EXTRACTION_ACTIVATED ||
      contractSigningStatus ===
        ContractSigningStatus.CONFIRMATION_EMAIL_FAILED ||
      contractSigningStatus ===
        ContractSigningStatus.SIGNED_CONTRACT_DOWNLOAD_FAILED ||
      contractSigningStatus ===
        ContractSigningStatus.SIGNED_CONTRACT_PERSISTENCE_FAILED;

    return !isGetSubscriptionError && isContractSigned;
  },
};

const mutations = {
  setActivateContractRequest(state: ContractActivationState) {
    state.activateContractLoading = true;
  },
  setActivateContractSuccess(state: ContractActivationState) {
    state.activateContractLoading = false;
  },
  setActivateContractFailure(state: ContractActivationState) {
    state.activateContractLoading = false;
  },
  setDownloadContractRequest(state: ContractActivationState) {
    state.downloadContractLoading = true;
  },
  setDownloadContractSuccess(state: ContractActivationState) {
    state.downloadContractLoading = false;
  },
  setDownloadContractFailure(state: ContractActivationState) {
    state.downloadContractLoading = false;
  },
};

const actions = {
  activateContract: (context: any) => {
    const isActivateContractLoading =
      context.getters.getActivateContractLoading;
    if (!isActivateContractLoading) {
      const subscriptionId: string =
        context.rootGetters["subscription/getSubscriptionId"];

      context.commit("setActivateContractRequest");

      const activateContractSuccess = () => {
        const successMessage = i18n.t(
          "contractActivation.successes.setActivateContractSuccess"
        );
        context.commit("setActivateContractSuccess");
        context.dispatch("subscription/getSubscription", null, { root: true });
        notification.sendSuccess(successMessage);
      };
      const activateContractFailure = (response: AxiosResponse) => {
        const errorResponse: ApiErrorResponse = response.data;
        const errorMessage = getActivateContractErrorMessage(errorResponse);

        context.commit("setActivateContractFailure");
        notification.sendError(errorMessage);
      };
      const activateContractAfter = () => {
        context
          .dispatch("subscription/getSubscription", null, { root: true })
          .finally(() => {
            const canReachContractActivationPage =
              store.getters[
                "subscription/contractActivation/canReachContractActivationPage"
              ];

            if (!canReachContractActivationPage) {
              router.replace({ name: RouteName.Contract });
            }
          });
      };

      apiContractActivation
        .activateContract(subscriptionId)
        .then(activateContractSuccess)
        .catch(activateContractFailure)
        .finally(activateContractAfter);
    }
  },
  downloadContract: (context: any) => {
    const isDownloadContractLoading =
      context.getters.getDownloadContractLoading;
    if (!isDownloadContractLoading) {
      const subscriptionId: string =
        context.rootGetters["subscription/getSubscriptionId"];

      context.commit("setDownloadContractRequest");

      const downloadContractSuccess = (response: Blob) => {
        const fileUrl = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement("a");

        const contractName =
          context.rootGetters["subscription/contractSigning/getContractName"];

        const fileName =
          `${i18n.t("contractActivation.contractName", {
            contractName,
          })}` + ".pdf";

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();

        context.commit("setDownloadContractSuccess");
      };
      const downloadContractFailure = () => {
        const errorMessage = i18n.t(
          "contractActivation.failures.setDownloadContractFailure"
        );

        context.commit("setDownloadContractFailure");
        notification.sendError(errorMessage);
      };

      apiContractActivation
        .downloadContract(subscriptionId)
        .then(downloadContractSuccess)
        .catch(downloadContractFailure);
    }
  },
};

const billing = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default billing;
