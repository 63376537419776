import { httpService } from "@/api";

const validateEmail = (token: string) => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/emailValidation/${token}`,
    null,
    {
      headers: {
        Authorization: process.env.VUE_APP_VALIDATION_EMAIL_TOKEN,
      },
    }
  );
};

export default {
  validateEmail,
};
