
import ReinsuranceHeader from "./reinsuranceHeader.vue";
import ReinsuranceContent from "./reinsuranceContent.vue";
import ReinsuranceFooter from "./reinsuranceFooter.vue";
export default {
  name: "ReinsurancePage",
  components: {
    ReinsuranceHeader,
    ReinsuranceContent,
    ReinsuranceFooter,
  },
};
