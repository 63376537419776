
import reinsuranceStep from "./reinsuranceStep.vue";
import { RouteName } from "@/store/navigation/type";

export default {
  name: "ReinsuranceContent",
  components: { reinsuranceStep },
  methods: {
    goNext() {
      this.$router.push({ name: RouteName.Offer });
    },
  },
};
