var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-steps',{attrs:{"has-navigation":_vm.isMobile,"size":_vm.isMobile ? 'is-small' : '',"value":_vm.currentStep,"vertical":!_vm.isMobile,"animated":"","label-position":"bottom","mobile-mode":"minimalist","step":"contactInformation"},on:{"input":(step) => _vm.changeStep(step)},scopedSlots:_vm._u([{key:"navigation",fn:function({ previous, next }){return (_vm.isMobile)?[_c('div',{staticClass:"step-navigation"},[_c('b-button',{attrs:{"disabled":previous.disabled,"icon-left":"arrow-left","type":"is-primary"},on:{"click":function($event){$event.preventDefault();return previous.action.apply(null, arguments)}}}),_c('b-button',{attrs:{"disabled":next.disabled,"icon-left":"arrow-right","type":"is-primary"},on:{"click":function($event){$event.preventDefault();return next.action.apply(null, arguments)}}})],1)]:undefined}}],null,true)},[_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.Offer ? 'offerStep' : 'offerStep is-previous',"icon":_vm.getIconType(_vm.RouteName.Offer),"label":_vm.$t('offer.yourOffer'),"type":_vm.getIconColor(_vm.RouteName.Offer),"value":_vm.RouteName.Offer}}),_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.ContactInformation
        ? 'contactInformationStep'
        : 'contactInformationStep is-previous',"icon":_vm.getIconType(_vm.RouteName.ContactInformation),"label":_vm.$t('contactInformation.yourContactDetails'),"type":_vm.getIconColor(_vm.RouteName.ContactInformation),"value":_vm.RouteName.ContactInformation,"clickable":_vm.checkIfStepFinished(_vm.RouteName.Offer)}}),_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.Housing
        ? 'housingStep'
        : 'housingStep is-previous',"icon":_vm.getIconType(_vm.RouteName.Housing),"label":_vm.$t('housing.yourAccommodation'),"type":_vm.getIconColor(_vm.RouteName.Housing),"value":_vm.RouteName.Housing,"clickable":_vm.checkIfStepFinished(_vm.RouteName.ContactInformation)}}),_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.Billing
        ? 'billingStep'
        : 'billingStep is-previous',"icon":_vm.getIconType(_vm.RouteName.Billing),"label":_vm.$t('billing.yourBilling'),"type":_vm.getIconColor(_vm.RouteName.Billing),"value":_vm.RouteName.Billing,"clickable":_vm.checkIfStepFinished(_vm.RouteName.Housing)}}),_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.BankDetails
        ? 'bankDetailsStep'
        : 'bankDetailsStep is-previous',"icon":_vm.getIconType(_vm.RouteName.BankDetails),"label":_vm.$t('bankDetails.yourBankDetails'),"type":_vm.getIconColor(_vm.RouteName.BankDetails),"value":_vm.RouteName.BankDetails,"clickable":_vm.checkIfStepFinished(_vm.RouteName.Billing)}}),_c('b-step-item',{attrs:{"headerClass":_vm.currentStep === _vm.RouteName.Contract
        ? 'contractStep'
        : 'contractStep is-previous',"icon":_vm.getIconType(_vm.RouteName.Contract),"label":_vm.$t('contract.yourContract'),"type":_vm.getIconColor(_vm.RouteName.Contract),"value":_vm.RouteName.Contract,"clickable":_vm.checkIfStepFinished(_vm.RouteName.BankDetails)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }