<template>
  <img
    alt="Mylight Systems"
    class="logo--mylight"
    src="@/assets/images/tenants/myLight/logoWithText.svg"
  />
</template>

<script>
export default {
  name: "MylightLogo",
};
</script>

<style lang="scss" scoped>
.logo--mylight {
  width: 200px;
  height: 90px;
}
</style>
