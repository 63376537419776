import { parsePhoneNumberWithError } from "libphonenumber-js/mobile";

export const stringValidators = {
  isNumberWithCorrectLength: (value: string, length: number) => {
    const re = new RegExp("^\\d{" + length + "}$");
    return re.test(value);
  },
  isNumber: (value: string) => {
    const re = new RegExp("^\\d+$");
    return re.test(value);
  },
  isName: (value: string, maxLength: number) => {
    const re = new RegExp(
      `^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,${maxLength}}$`
    );
    return re.test(value);
  },
  isFrenchIban: (value: string) => {
    const re = new RegExp(
      `^FR[0-9]{2} ?([a-zA-Z0-9]{4} ?){5}([a-zA-Z0-9]{2,3})$`
    );
    return re.test(value);
  },
  isBic: (value: string) => {
    const re = new RegExp(
      `^[a-zA-Z0-9]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[a-zA-Z0-9]{0,3}$`
    );
    return re.test(value);
  },
  isEmail: (value: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(value).toLowerCase());
  },
  isEmpty: (value: string | null | undefined) => {
    return value === null || value === "" || value === undefined;
  },
  isMobilePhone: (value: string) => {
    try {
      const phoneNumber = parsePhoneNumberWithError(value, "FR");
      return phoneNumber.isValid() && phoneNumber.getType() === "MOBILE";
    } catch (error) {
      return false;
    }
  },
};
