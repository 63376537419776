<template>
  <div id="mainLayout" :class="{ withMobileStepHeader }">
    <MainSidebar>
      <slot name="sidebar" />
    </MainSidebar>
    <MainHeader />
    <MobileStepHeader v-if="withMobileStepHeader" />
    <MainContent :is-mobile-navigation="withMobileStepHeader">
      <slot name="content" />
    </MainContent>
    <b-loading :active="loading"></b-loading>
  </div>
</template>

<script>
import MainSidebar from "@/components/layout/MainSidebar";
import MainHeader from "@/components/layout/MainHeader";
import MobileStepHeader from "@/components/layout/MobileStepHeader";
import MainContent from "@/components/layout/MainContent";

export default {
  name: "MainLayout",
  components: { MainSidebar, MainHeader, MobileStepHeader, MainContent },
  props: {
    withMobileStepHeader: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
#mainLayout {
  display: grid;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  grid-template-columns: 200px 1fr;
  grid-template-rows: $desktop-height-main-header 0 1fr;
}

@include media("<tablet") {
  #mainLayout {
    grid-template-rows: $mobile-height-main-header 0 1fr;

    &.withMobileStepHeader {
      grid-template-rows: $mobile-height-main-header 60px 1fr;
    }
  }
}

.loading-overlay :deep(.loading-background) {
  background-color: $lightGrey;
}
</style>
