import { httpService } from "@/api";
import { GridOperatorEnum } from "@/store/subscription/housing/type";

const validateGsrnEan = (
  gsrnEanNumber: string,
  gridOperatorEnum: GridOperatorEnum
) => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/GsrnEanValidation/formatvalidity`,
    {
      gridOperatorEnum: gridOperatorEnum,
      gsrnEanNumber: gsrnEanNumber,
    },
    {
      headers: {
        Authorization: process.env.VUE_APP_VALIDATION_GSRN_TOKEN,
      },
    }
  );
};

export default {
  validateGsrnEan,
};
