import { BillingAddress } from "@/store/subscription/bankDetails/type";

export const MAX_LENGTH_HOLDER_FIRST_NAME = 50;
export const MAX_LENGTH_HOLDER_FAMILY_NAME = 50;
export const MAX_LENGTH_HOLDER_COMPANY_NAME = 50;
export const MAX_LENGTH_IBAN = 33; // 27 + 5 spaces
export const MAX_LENGTH_BIC = 11;
export const DEFAULT_EMPTY_BILLING_ADDRESS: BillingAddress = {
  streetNumber: "",
  streetNumberSuffix: null,
  street: "",
  postCode: "",
  city: "",
};
