export interface BillingState extends Billing {
  paymentType: PaymentType | null;
  paymentTypes: PaymentType[];
}

export interface GetBillingQuery {
  paymentType: PaymentType;
}

export interface Billing {
  paymentType: PaymentType | null;
}

export enum PaymentType {
  CLASSIC = "CLASSIC",
  REAL_TIME = "REAL_TIME",
}
