import { httpService } from "@/api";
import { YousignIframeId } from "@/store/subscription/contractSigning/type";

interface StartContractSigningProcess {
  subscriptionId: string;
  termsAndConditionsAccepted: boolean;
  solarPanelsInstalled: boolean;
}

const startContractSigningProcess = ({
  subscriptionId,
  termsAndConditionsAccepted,
  solarPanelsInstalled,
}: StartContractSigningProcess): Promise<unknown> => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}/contractSigningProcess`,
    { termsAndConditionsAccepted, solarPanelsInstalled }
  );
};

const getContractSigningProgress = (
  subscriptionId: string
): Promise<YousignIframeId> => {
  return httpService.get(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/subscriptions/${subscriptionId}/contractSigningProgress`
  );
};

export default {
  startContractSigningProcess,
  getContractSigningProgress,
};
