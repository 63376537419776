import { GridOperator } from "@/store/subscription/housing/type";
import { GridOperatorState } from "@/store/subscription/gridOperator/type";
import { AxiosResponse } from "axios";
import apiGridOperator from "@/api/gridOperator";

const state: GridOperatorState = {
  gridOperators: [],
  gridOperatorsLoading: false,
  gridOperatorsError: null,
};

const getters = {
  getGridOperators(state: GridOperatorState): Array<GridOperator> {
    return state.gridOperators;
  },
  getGridOperatorsLoading(state: GridOperatorState): boolean {
    return state.gridOperatorsLoading;
  },
  getGridOperatorsError(state: GridOperatorState): string | null {
    return state.gridOperatorsError;
  },
};

const mutations = {
  setGetGridOperatorsRequest(state: GridOperatorState) {
    state.gridOperatorsError = null;
    state.gridOperatorsLoading = true;
  },
  setGetGridOperatorsSuccess(
    state: GridOperatorState,
    gridOperators: GridOperator[]
  ) {
    state.gridOperators = gridOperators;
    state.gridOperatorsError = null;
    state.gridOperatorsLoading = false;
  },
  setGetGridOperatorsFailure(state: GridOperatorState, errorMessage: string) {
    state.gridOperators = [];
    state.gridOperatorsError = errorMessage;
    state.gridOperatorsLoading = false;
  },
};

const actions = {
  getGridOperators: (context: any) => {
    context.commit("setGetGridOperatorsRequest");

    const getGridOperatorsSuccess = (gridOperators: GridOperator[]) => {
      context.commit("setGetGridOperatorsSuccess", gridOperators);
      return Promise.resolve(gridOperators);
    };

    const getGridOperatorsFailure = (error: AxiosResponse) => {
      const errorMessage = error.data?.title || error.statusText;
      context.commit("setGetGridOperatorsFailure", errorMessage);
      return Promise.reject(errorMessage);
    };

    return apiGridOperator
      .getGridOperators()
      .then(getGridOperatorsSuccess)
      .catch(getGridOperatorsFailure);
  },
};

const gridOperator = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default gridOperator;
