import { httpService } from "@/api";

const activateContract = (subscriptionId: string): Promise<unknown> => {
  return httpService.post(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/Contracts/${subscriptionId}/activation`
  );
};

const downloadContract = (subscriptionId: string): Promise<Blob> => {
  return httpService.get(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/Contracts/${subscriptionId}`,
    { responseType: "blob" }
  );
};

export default {
  activateContract,
  downloadContract,
};
