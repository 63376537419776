import { EmailValidationState } from "@/store/subscription/emailValidation/type";
import apiSubscription from "@/api/subscription";
import apiEmailValidation from "@/api/emailValidation";
import notification from "@/store/notification/helper";
import i18n from "@/i18n";
import { AxiosResponse } from "axios";
import { ApiError } from "@/api/type";

const state: EmailValidationState = {
  sendEmailValidationLoading: false,
  sendEmailValidationError: null,
  sendEmailValidationForUpdateEmailLoading: false,
  sendEmailValidationForUpdateEmailError: null,
  reSendEmailValidationLoading: false,
  reSendEmailValidationError: null,
  reSendEmailValidationSuccess: false,
  validatedEmailLoading: false,
  validatedEmailError: null,
  emailIsValidated: false,
};

const getters = {
  getSendEmailValidationError() {
    return state.reSendEmailValidationError;
  },
  isSendEmailValidationLoading() {
    return state.sendEmailValidationLoading;
  },
  getSendEmailValidationForUpdateEmailError() {
    return state.sendEmailValidationForUpdateEmailError;
  },
  isSendEmailValidationForUpdateEmailLoading() {
    return state.sendEmailValidationForUpdateEmailLoading;
  },
  getReSendEmailValidationError(): ApiError[] | null {
    return state.reSendEmailValidationError;
  },
  getReSendEmailValidationSuccess() {
    return state.reSendEmailValidationSuccess;
  },
  isValidatedEmail() {
    return state.emailIsValidated;
  },
};

const mutations = {
  setSendEmailValidationRequest(state: EmailValidationState) {
    state.sendEmailValidationLoading = true;
    state.sendEmailValidationError = null;
  },
  setSendEmailValidationSuccess(state: EmailValidationState) {
    state.sendEmailValidationLoading = false;
  },
  setSendEmailValidationFailure(
    state: EmailValidationState,
    error: Array<ApiError>
  ) {
    state.sendEmailValidationLoading = false;
    state.sendEmailValidationError = error;
  },
  setSendEmailValidationForUpdateEmailRequest(state: EmailValidationState) {
    state.sendEmailValidationForUpdateEmailLoading = true;
    state.sendEmailValidationForUpdateEmailError = null;
  },
  setSendEmailValidationForUpdateEmailSuccess(state: EmailValidationState) {
    state.sendEmailValidationForUpdateEmailLoading = false;
  },
  setSendEmailValidationForUpdateEmailFailure(
    state: EmailValidationState,
    error: string
  ) {
    state.sendEmailValidationForUpdateEmailLoading = false;
    state.sendEmailValidationForUpdateEmailError = error;
  },
  setResendEmailValidationRequest(state: EmailValidationState) {
    state.reSendEmailValidationLoading = true;
    state.reSendEmailValidationSuccess = false;
    state.reSendEmailValidationError = null;
  },
  setResendEmailValidationSuccess(state: EmailValidationState) {
    state.reSendEmailValidationLoading = false;
    state.reSendEmailValidationSuccess = true;
  },
  setResendEmailValidationFailure(
    state: EmailValidationState,
    error: ApiError[]
  ) {
    state.reSendEmailValidationLoading = false;
    state.reSendEmailValidationError = error;
  },
  setValidateEmailRequest(state: EmailValidationState) {
    state.validatedEmailLoading = true;
    state.validatedEmailError = null;
    state.emailIsValidated = false;
  },
  setValidateEmailSuccess(state: EmailValidationState) {
    state.validatedEmailLoading = false;
    state.emailIsValidated = true;
  },
  setValidateEmailFailure(state: EmailValidationState, error: string) {
    state.validatedEmailLoading = false;
    state.validatedEmailError = error;
  },
};

const actions = {
  sendValidationEmailForUpdateEmail: (context: any) => {
    const subscriptionId =
      context.rootGetters["subscription/getSubscriptionId"];

    context.commit("setSendEmailValidationForUpdateEmailRequest");

    return apiSubscription
      .emailValidationProcess(subscriptionId)
      .then(() => {
        context.commit("setSendEmailValidationForUpdateEmailSuccess");
        notification.sendSuccess(i18n.t("email.successes.sendingEmail"));
        return Promise.resolve();
      })
      .catch((error) => {
        context.commit("setSendEmailValidationForUpdateEmailFailure", error);
        notification.sendError(i18n.t("email.errors.sendingEmail"));
        return Promise.reject();
      });
  },
  sendValidationEmail: (context: any, subscriptionId: string) => {
    context.commit("setSendEmailValidationRequest");

    return apiSubscription
      .emailValidationProcess(subscriptionId)
      .then(() => {
        context.commit("setSendEmailValidationSuccess");
        notification.sendSuccess(i18n.t("email.successes.sendingEmail"));
        return Promise.resolve();
      })
      .catch((response: AxiosResponse) => {
        const errors = response.data.errors as Array<ApiError>;
        context.commit("setSendEmailValidationFailure", errors);

        if (
          errors.some(
            (error) =>
              error.code === "EMAIL_VALIDATION_PROCESS_ALREADY_INITIATED"
          )
        ) {
          notification.sendWarning(
            i18n.t("email.errors.EMAIL_VALIDATION_PROCESS_ALREADY_INITIATED")
          );
          return Promise.resolve();
        }

        if (errors.some((error) => error.code === "EMAIL_ALREADY_VALIDATED")) {
          notification.sendError(
            i18n.t("email.errors.EMAIL_ALREADY_VALIDATED"),
            0
          );
        } else {
          notification.sendError(i18n.t("email.errors.sendingEmail"));
        }

        return Promise.reject();
      });
  },
  reSendValidationEmail: (context: any) => {
    const subscriptionId =
      context.rootGetters["subscription/getSubscriptionId"];

    context.commit("setResendEmailValidationRequest");

    return apiSubscription
      .emailValidationProcess(subscriptionId, true)
      .then(() => {
        context.commit("setResendEmailValidationSuccess");
        return Promise.resolve();
      })
      .catch((response: AxiosResponse) => {
        const errors = response.data.errors as Array<ApiError>;
        context.commit("setResendEmailValidationFailure", errors);

        return Promise.reject(errors);
      });
  },
  validateEmail: (context: any, token: string) => {
    context.commit("setValidateEmailRequest");

    return apiEmailValidation
      .validateEmail(token)
      .then((subscriptionId) => {
        context.commit("setValidateEmailSuccess");
        return Promise.resolve(subscriptionId);
      })
      .catch((error) => {
        context.commit("setValidateEmailFailure", error);
        notification.sendError(i18n.t("email.errors.validateEmail"));
        return Promise.reject();
      });
  },
};

const emailValidation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default emailValidation;
