<template>
  <div
    id="mainContent"
    :class="{ mobileNavigation: isMobileNavigation, withoutPadding }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "MainContent",
  data() {
    return {
      tenant: process.env.VUE_APP_TENANT,
    };
  },
  props: {
    isMobileNavigation: {
      type: Boolean,
      default: false,
    },
    withoutPadding: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    fixCrappyScrollBehavior() {
      document.getElementById("mainContent").scrollTop = 0;
    },
  },
  watch: {
    $route() {
      this.fixCrappyScrollBehavior();
    },
  },
};
</script>

<style lang="scss" scoped>
#mainContent {
  grid-column: 2;
  grid-row-start: 2;
  grid-row-end: 3 span;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  z-index: 4;
  padding: 2rem 0;

  &.withoutPadding {
    padding: 0;
  }
}

@include media("<tablet") {
  #mainContent {
    grid-column-start: 1;
    grid-column-end: 2 span;
    grid-row-start: 3;
    grid-row-end: 3;
    padding: 1rem 0;

    &.mobileNavigation {
      margin-bottom: 68px;
      border-bottom: 2px solid $greyBackground;
    }
  }
}
</style>
