import {
  ClientType,
  ContactInformationState,
  Cosigner,
  GetContactInformationQuery,
  Prefix,
  Tenant,
} from "@/store/subscription/contactInformation/type";
import {
  MAX_LENGTH_COMPANY_IDENTIFICATION_NUMBER,
  MAX_LENGTH_FAMILY_NAME,
  MAX_LENGTH_FIRST_NAME,
} from "@/store/subscription/contactInformation/constant";
import { stringValidators } from "@/store/subscription/validators";
import i18n from "@/i18n";
import { TranslateResult } from "vue-i18n";
import { DEFAULT_EMPTY_COSIGNER } from "./constant";

export const state: ContactInformationState = {
  companyName: "",
  companyIdentificationNumber: "",
  prefix: null,
  familyName: "",
  firstName: "",
  email: "",
  mobilePhone: "",
  tenant: process.env.VUE_APP_TENANT
    ? (process.env.VUE_APP_TENANT as Tenant)
    : Tenant.MYL,
  clientType: ClientType.RES,
  identificationNumber: null,
  cosigner: DEFAULT_EMPTY_COSIGNER,
  cosignerExists: false,
};

export const getters = {
  getClientType(state: ContactInformationState) {
    return state.clientType;
  },
  isIndividualClient(state: ContactInformationState): boolean {
    return state.clientType === ClientType.RES;
  },
  isProfessionalClient(state: ContactInformationState): boolean {
    return state.clientType === ClientType.PRO;
  },
  getCompanyName(state: ContactInformationState) {
    return state.companyName;
  },
  isCompanyNameEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.companyName);
  },
  isCompanyNameValid(state: ContactInformationState, getters: any) {
    return !getters.isCompanyNameEmpty;
  },
  getCompanyNameErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isCompanyNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },

  getCompanyIdentificationNumber(state: ContactInformationState) {
    return state.companyIdentificationNumber;
  },
  isCompanyIdentificationNumberEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.companyIdentificationNumber);
  },
  isCompanyIdentificationNumberBadFormat(state: ContactInformationState) {
    return !stringValidators.isNumberWithCorrectLength(
      state.companyIdentificationNumber,
      MAX_LENGTH_COMPANY_IDENTIFICATION_NUMBER
    );
  },
  isCompanyIdentificationNumberValid(
    state: ContactInformationState,
    getters: any
  ) {
    return (
      !getters.isCompanyIdentificationNumberEmpty &&
      !getters.isCompanyIdentificationNumberBadFormat
    );
  },
  getCompanyIdentificationNumberErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isCompanyIdentificationNumberEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isCompanyIdentificationNumberBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getPrefix(state: ContactInformationState) {
    return state.prefix;
  },
  getPrefixErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isPrefixEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },
  isPrefixEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.prefix as string | null);
  },
  isPrefixValid(state: ContactInformationState, getters: any) {
    return !getters.isPrefixEmpty;
  },

  getFamilyName(state: ContactInformationState) {
    return state.familyName;
  },
  isFamilyNameEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.familyName);
  },
  isFamilyNameBadFormat(state: ContactInformationState) {
    return !stringValidators.isName(state.familyName, MAX_LENGTH_FAMILY_NAME);
  },
  isFamilyNameValid(state: ContactInformationState, getters: any) {
    return !getters.isFamilyNameEmpty && !getters.isFamilyNameBadFormat;
  },
  getFamilyNameErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isFamilyNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isFamilyNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getFirstName(state: ContactInformationState) {
    return state.firstName;
  },
  isFirstNameEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.firstName);
  },
  isFirstNameBadFormat(state: ContactInformationState) {
    return !stringValidators.isName(state.firstName, MAX_LENGTH_FIRST_NAME);
  },
  isFirstNameValid(state: ContactInformationState, getters: any) {
    return !getters.isFirstNameEmpty && !getters.isFirstNameBadFormat;
  },
  getFirstNameErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isFirstNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isFirstNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }

    return errors;
  },

  getCosignerPrefix(state: ContactInformationState) {
    return state.cosigner?.prefix;
  },
  getCosignerPrefixErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isCosignerPrefixEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    }
    return errors;
  },
  isCosignerPrefixEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.cosigner?.prefix as string | null);
  },
  isCosignerPrefixValid(state: ContactInformationState, getters: any) {
    return !getters.isCosignerPrefixEmpty;
  },

  getCosignerExists(state: ContactInformationState) {
    return state.cosignerExists;
  },
  getCosignerFamilyName(state: ContactInformationState) {
    return state.cosigner?.familyName;
  },
  isCosignerFamilyNameEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.cosigner?.familyName);
  },
  isCosignerFamilyNameBadFormat(state: ContactInformationState) {
    return !stringValidators.isName(
      state.cosigner?.familyName || "",
      MAX_LENGTH_FAMILY_NAME
    );
  },
  isCosignerFamilyNameValid(state: ContactInformationState, getters: any) {
    return (
      !getters.isCosignerFirstNameEmpty && !getters.isCosignerFirstNameBadFormat
    );
  },
  getCosignerFamilyNameErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isCosignerFamilyNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isCosignerFamilyNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },
  getCosignerFirstName(state: ContactInformationState) {
    return state.cosigner.firstName;
  },
  isCosignerFirstNameEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.cosigner.firstName);
  },
  isCosignerFirstNameBadFormat(state: ContactInformationState) {
    return !stringValidators.isName(
      state.cosigner?.firstName || "",
      MAX_LENGTH_FIRST_NAME
    );
  },
  isCosignerFirstNameValid(state: ContactInformationState, getters: any) {
    return (
      !getters.isCosignerFamilyNameEmpty &&
      !getters.isCosignerFamilyNameBadFormat
    );
  },
  getCosignerFirstNameErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isCosignerFirstNameEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isCosignerFirstNameBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }

    return errors;
  },
  isCosignerValid(state: ContactInformationState, getters: any) {
    return (
      !state.cosignerExists ||
      (getters.isCosignerFamilyNameValid &&
        getters.isCosignerFirstNameValid &&
        getters.isCosignerPrefixValid)
    );
  },

  getMobilePhone(state: ContactInformationState) {
    return state.mobilePhone;
  },
  isMobilePhoneEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.mobilePhone);
  },
  isMobilePhoneBadFormat(state: ContactInformationState) {
    return !stringValidators.isMobilePhone(state.mobilePhone);
  },
  isMobilePhoneBadPhoneNumber(state: ContactInformationState) {
    return !state.mobilePhone.startsWith("0");
  },
  isMobilePhoneValid(state: ContactInformationState, getters: any) {
    return (
      !getters.isMobilePhoneEmpty &&
      !getters.isMobilePhoneBadFormat &&
      !getters.isMobilePhoneBadPhoneNumber
    );
  },
  getMobilePhoneErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isMobilePhoneEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isMobilePhoneBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    } else if (getters.isMobilePhoneBadPhoneNumber) {
      errors.push(i18n.t("common.formError.fieldHasBadPhone"));
    }
    return errors;
  },

  getEmail(state: ContactInformationState) {
    return state.email;
  },
  isEmailEmpty(state: ContactInformationState) {
    return stringValidators.isEmpty(state.email);
  },
  isEmailBadFormat(state: ContactInformationState) {
    return !stringValidators.isEmail(state.email);
  },
  isEmailValid(state: ContactInformationState, getters: any) {
    return !getters.isEmailEmpty && !getters.isEmailBadFormat;
  },
  getEmailErrors(
    state: ContactInformationState,
    getters: any
  ): Array<TranslateResult> {
    const errors = [];
    if (getters.isEmailEmpty) {
      errors.push(i18n.t("common.formError.fieldMustBeInquired"));
    } else if (getters.isEmailBadFormat) {
      errors.push(i18n.t("common.formError.fieldHasBadFormat"));
    }
    return errors;
  },

  getTenant(state: ContactInformationState) {
    return state.tenant;
  },

  getIdentificationNumber(state: ContactInformationState) {
    return state.identificationNumber;
  },

  getContactInformation(state: ContactInformationState, getters: any) {
    const contactInformation: any = {
      prefix: state.prefix,
      familyName: state.familyName,
      firstName: state.firstName,
      email: state.email,
      mobilePhone: state.mobilePhone,
      tenant: state.tenant,
      clientType: state.clientType,
      identificationNumber: state.identificationNumber,
    };

    if (getters.isIndividualClient) {
      if (state.cosignerExists) {
        contactInformation.cosigner = state.cosigner;
      }

      return contactInformation;
    } else {
      return {
        ...contactInformation,
        companyName: state.companyName,
        companyIdentificationNumber: state.companyIdentificationNumber,
      };
    }
  },
  isContactInformationStepValid(state: ContactInformationState, getters: any) {
    if (getters.isIndividualClient) {
      return (
        getters.isPrefixValid &&
        getters.isFamilyNameValid &&
        getters.isFirstNameValid &&
        getters.isEmailValid &&
        getters.isMobilePhoneValid &&
        getters.isCosignerValid
      );
    }

    if (getters.isProfessionalClient) {
      return (
        getters.isCompanyNameValid &&
        getters.isCompanyIdentificationNumberValid &&
        getters.isPrefixValid &&
        getters.isFamilyNameValid &&
        getters.isFirstNameValid &&
        getters.isEmailValid &&
        getters.isMobilePhoneValid
      );
    }

    return false;
  },
};

export const mutations = {
  setClientType(state: ContactInformationState, clientType: ClientType) {
    state.clientType = clientType;
  },
  // COMPANY_NAME
  setCompanyName(state: ContactInformationState, companyName: string) {
    state.companyName = companyName;
  },
  // COMPANY_IDENTIFICATION_NUMBER
  setCompanyIdentificationNumber(
    state: ContactInformationState,
    companyIdentificationNumber: string
  ) {
    state.companyIdentificationNumber = companyIdentificationNumber;
  },
  setPrefix(state: ContactInformationState, prefix: Prefix) {
    state.prefix = prefix;
  },
  setFamilyName(state: ContactInformationState, familyName: string) {
    state.familyName = familyName;
  },
  setFirstName(state: ContactInformationState, firstName: string) {
    state.firstName = firstName;
  },
  setCosignerPrefix(state: ContactInformationState, prefix: Prefix) {
    state.cosigner.prefix = prefix;
  },
  setCosignerFamilyName(state: ContactInformationState, familyName: string) {
    state.cosigner.familyName = familyName;
  },
  setCosignerFirstName(state: ContactInformationState, firstName: string) {
    state.cosigner.firstName = firstName;
  },
  setCosignerExists(state: ContactInformationState, cosignerExists: boolean) {
    state.cosignerExists = cosignerExists;
  },
  setCosigner(state: ContactInformationState, cosigner: Cosigner) {
    state.cosigner = cosigner ? { ...cosigner } : DEFAULT_EMPTY_COSIGNER;
  },
  setEmail(state: ContactInformationState, email: string) {
    state.email = email;
  },
  setMobilePhone(state: ContactInformationState, mobilePhone: string) {
    state.mobilePhone = mobilePhone;
  },
  setTenant(state: ContactInformationState, tenant: Tenant) {
    state.tenant = tenant;
  },
  setIdentificationNumber(
    state: ContactInformationState,
    identificationNumber: number
  ) {
    state.identificationNumber = identificationNumber;
  },
};

export const actions = {
  setContactInformation: (
    context: any,
    contactInformation: GetContactInformationQuery
  ) => {
    context.commit("setCompanyName", contactInformation.companyName);
    context.commit(
      "setCompanyIdentificationNumber",
      contactInformation.companyIdentificationNumber
    );
    context.commit("setPrefix", contactInformation.prefix);
    context.commit("setFamilyName", contactInformation.familyName);
    context.commit("setFirstName", contactInformation.firstName);
    context.commit("setEmail", contactInformation.email);
    context.commit("setMobilePhone", contactInformation.mobilePhone);
    context.commit("setClientType", contactInformation.clientType);
  },
  setCosigner: (context: any, cosigner: Cosigner | null) => {
    const cosignerExists = !!cosigner;
    context.commit("setCosignerExists", cosignerExists);
    context.commit("setCosigner", cosigner);
  },
};

const contactInformation = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default contactInformation;
