import {
  ContractSigning,
  ContractSigningState,
  ContractSigningStatus,
  GetContractSigningQuery,
  YousignIframeId,
} from "@/store/subscription/contractSigning/type";
import contractSigningApi from "@/api/contractSigning";
import notification from "@/store/notification/helper";
import i18n from "@/i18n";
import {
  CONTRACT_SIGNING_PROGRESS_POLL_INTERVAL,
  YOUSIGN_IFRAME_URL,
} from "@/store/subscription/contractSigning/constant";
import VueI18n from "vue-i18n";
import { stringValidators } from "@/store/subscription/validators";
import { RouteName } from "@/store/navigation/type";
import router from "@/router";

const state: ContractSigningState = {
  requestId: 0,

  contractSigningProcessLoading: false,

  contractSigningProgressLoading: false,
  contractSigningProgressPolling: null,

  contractSigningStatus: null,
  contractSigningStatusMessage: null,

  yousignIframeId: null,
  contractName: null,
  activationDate: null,
  signatureDate: null,
};

const getters = {
  getContractSigningStatusMessage(
    state: ContractSigningState
  ): VueI18n.TranslateResult | null {
    return state.contractSigningStatusMessage;
  },

  getContractSigningStatus(
    state: ContractSigningState
  ): ContractSigningStatus | null {
    return state.contractSigningStatus;
  },
  getContractName(state: ContractSigningState): string | null {
    return state.contractName;
  },

  getContractSigningProcessLoading(state: ContractSigningState): boolean {
    return state.contractSigningProcessLoading;
  },

  getContractSigningProgressLoading(state: ContractSigningState): boolean {
    return state.contractSigningProgressLoading;
  },

  isYousignIframeIdValid(state: ContractSigningState): boolean {
    return (
      typeof state.yousignIframeId === "string" &&
      !stringValidators.isEmpty(state.yousignIframeId)
    );
  },
  getYousignIframeUrl(
    state: ContractSigningState,
    getters: any
  ): string | undefined {
    const isYousignIframeIdValid = getters.isYousignIframeIdValid;

    const yousignTemplateId =
      process.env.VUE_APP_TENANT === "DOM"
        ? process.env.VUE_APP_YOUSIGN_DOM_TEMPLATE_ID
        : process.env.VUE_APP_YOUSIGN_MYL_TEMPLATE_ID;

    return isYousignIframeIdValid
      ? `${YOUSIGN_IFRAME_URL}${state.yousignIframeId}&signatureUi=/signature_uis/${yousignTemplateId}`
      : undefined;
  },
  getContractSigning(state: ContractSigningState): ContractSigning {
    const {
      yousignIframeId,
      contractName,
      contractSigningStatus,
      activationDate,
      signatureDate,
    } = state;

    return {
      yousignIframeId,
      contractName,
      contractSigningStatus,
      activationDate,
      signatureDate,
    };
  },
};

const mutations = {
  setStartContractSigningProcessRequest(
    state: ContractSigningState,
    requestId: number
  ) {
    state.requestId = requestId;

    state.contractSigningProcessLoading = true;
    state.contractSigningStatusMessage = i18n.t(
      "contractSigning.statusMessages.setStartContractSigningProcessRequest"
    );

    state.contractSigningProgressPolling = null;
  },
  setStartContractSigningProcessSuccess(state: ContractSigningState) {
    state.contractSigningProcessLoading = false;
    state.contractSigningStatusMessage = i18n.t(
      "contractSigning.statusMessages.setStartContractSigningProcessSuccess"
    );
  },
  setStartContractSigningProcessFailure(state: ContractSigningState) {
    state.contractSigningProcessLoading = false;
    state.contractSigningStatusMessage = null;
  },

  setGetContractSigningProgressRequest(state: ContractSigningState) {
    state.yousignIframeId = null;

    state.contractSigningProgressLoading = true;
    state.contractSigningStatusMessage = i18n.t(
      "contractSigning.statusMessages.setGetContractSigningProgressRequest"
    );
  },
  setGetContractSigningProgressSuccess(
    state: ContractSigningState,
    yousignIframeId: string
  ) {
    state.yousignIframeId = yousignIframeId;

    state.contractSigningProgressLoading = false;
    state.contractSigningStatusMessage = i18n.t(
      "contractSigning.statusMessages.setGetContractSigningProgressSuccess"
    );
  },
  setGetContractSigningProgressFailure(state: ContractSigningState) {
    state.yousignIframeId = null;

    state.contractSigningProgressLoading = false;
    state.contractSigningStatusMessage = null;
  },
  setGetContractSigningProgressPollingStart(
    state: ContractSigningState,
    pollingFunction: () => void
  ) {
    state.contractSigningProgressPolling = setTimeout(
      pollingFunction,
      CONTRACT_SIGNING_PROGRESS_POLL_INTERVAL
    );
  },
  setGetContractSigningProgressPollingStop(state: ContractSigningState) {
    if (state.contractSigningProgressPolling) {
      clearTimeout(state.contractSigningProgressPolling);
    }
    state.contractSigningProgressPolling = null;
    state.requestId = 0;
  },

  setContractSigningState(
    state: ContractSigningState,
    contractSignatureProcess: GetContractSigningQuery
  ) {
    state.yousignIframeId = contractSignatureProcess.yousignIframeId || null;
    state.contractSigningStatus = contractSignatureProcess.state || null;
    state.contractName = contractSignatureProcess.contractName || null;
    state.activationDate = contractSignatureProcess.activationDate;
    state.signatureDate = contractSignatureProcess.signatureDate;
  },
};

const actions = {
  showErrorAndRedirectToContractPage(
    context: any,
    errorMessage: string | VueI18n.TranslateResult
  ) {
    notification.sendError(errorMessage);
    router.push({ name: RouteName.Contract });
  },
  startContractSigningProcess(context: any, requestId: number) {
    const subscriptionId: string =
      context.rootGetters["subscription/getSubscriptionId"];
    const termsAndConditionsAccepted =
      context.rootGetters["subscription/contract/getTermsAndConditions"];
    const solarPanelsInstalled =
      context.rootGetters["subscription/contract/getSolarPanelsInstalled"];

    context.commit("setStartContractSigningProcessRequest", requestId);

    const startContractSigningProcessSuccess = () => {
      context.commit("setStartContractSigningProcessSuccess");
      context.dispatch("getContractSigningProgress", requestId);
    };
    const startContractSigningProcessFailure = () => {
      const errorMessage = i18n.t(
        "contractSigning.failures.setStartContractSigningProcessFailure"
      );

      context.dispatch("stopContractSigningProgressPolling");
      context.commit("setStartContractSigningProcessFailure", errorMessage);
      context.dispatch("showErrorAndRedirectToContractPage", errorMessage);
    };

    contractSigningApi
      .startContractSigningProcess({
        subscriptionId,
        termsAndConditionsAccepted,
        solarPanelsInstalled,
      })
      .then(() => {
        if (context.state.requestId === requestId) {
          startContractSigningProcessSuccess();
        }
      })
      .catch(() => {
        if (context.state.requestId === requestId) {
          startContractSigningProcessFailure();
        }
      });
  },
  getContractSigningProgress(context: any, requestId: number) {
    const subscriptionId: string =
      context.rootGetters["subscription/getSubscriptionId"];

    context.commit("setGetContractSigningProgressRequest");

    const getContractSigningProgressSuccess = (yousignIframeId: string) => {
      context.dispatch("stopContractSigningProgressPolling");
      context.commit("setGetContractSigningProgressSuccess", yousignIframeId);
    };

    const getContractSigningProgressFailure = () => {
      const errorMessage = i18n.t(
        "contractSigning.failures.setGetContractSigningProgressFailure"
      );

      context.dispatch("stopContractSigningProgressPolling");
      context.commit("setGetContractSigningProgressFailure", errorMessage);
      context.dispatch("showErrorAndRedirectToContractPage", errorMessage);
    };

    const getContractSigningProgress = () => {
      contractSigningApi
        .getContractSigningProgress(subscriptionId)
        .then((yousignIframeId?: YousignIframeId) => {
          if (context.state.requestId === requestId) {
            if (yousignIframeId) {
              getContractSigningProgressSuccess(yousignIframeId);
            } else {
              context.commit(
                "setGetContractSigningProgressPollingStart",
                getContractSigningProgress
              );
            }
          }
        })
        .catch(() => {
          if (context.state.requestId === requestId) {
            getContractSigningProgressFailure();
          }
        });
    };

    context.commit(
      "setGetContractSigningProgressPollingStart",
      getContractSigningProgress
    );
  },
  stopContractSigningProgressPolling(context: any) {
    context.commit("setGetContractSigningProgressPollingStop");
  },
  setContractSigning(
    context: any,
    contractSignatureProcess: GetContractSigningQuery
  ) {
    context.commit("setContractSigningState", contractSignatureProcess);

    switch (contractSignatureProcess.state) {
      case ContractSigningStatus.SIGNED:
      case ContractSigningStatus.PENDING_ACTIVATION:
      case ContractSigningStatus.ACTIVATION_REQUESTED:
      case ContractSigningStatus.GRID_EXTRACTION_ACTIVATED:
      case ContractSigningStatus.SIGNED_CONTRACT_DOWNLOAD_FAILED:
      case ContractSigningStatus.SIGNED_CONTRACT_PERSISTENCE_FAILED:
      case ContractSigningStatus.CONFIRMATION_EMAIL_FAILED: {
        if (router.currentRoute.name !== RouteName.ContractActivation) {
          const subscriptionId: string =
            context.rootGetters["subscription/getSubscriptionId"];

          return router.replace({
            name: RouteName.ContractActivation,
            query: { id: subscriptionId },
          });
        }
        break;
      }
      case ContractSigningStatus.PENDING:
      case ContractSigningStatus.REFUSED:
        if (router.currentRoute.name !== RouteName.Contract) {
          return router.replace({ name: RouteName.Contract });
        }
        break;
      default: {
        const isSubscriptionValidForSign =
          context.rootGetters["subscription/isSubscriptionValidForSign"];
        if (
          isSubscriptionValidForSign &&
          router.currentRoute.name !== RouteName.Contract
        ) {
          return router.replace({ name: RouteName.Contract });
        }
        break;
      }
    }
  },
};

const contractSigning = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default contractSigning;
