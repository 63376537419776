<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GRD } from "@/store/subscription/gridOperator/constants";

export default {
  mounted() {
    this.setViewportHeight();
    this.$nextTick(() => {
      window.addEventListener("resize", this.setViewportHeight);
      window.addEventListener("orientationchange", this.setViewportHeight);
    });
  },
  created() {
    this.getGridOperators()
      .then((gridOperators) => {
        const currentOperatorId = this.gridOperatorId;
        if (!currentOperatorId) {
          const enedis = gridOperators.find((gop) => gop.name === GRD.ENEDIS);
          if (enedis) {
            this.setGridOperatorId(enedis.id);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setViewportHeight);
    window.removeEventListener("orientationchange", this.setViewportHeight);
  },
  computed: {
    ...mapGetters("subscription/housing", {
      gridOperatorId: "getGridOperatorId",
    }),
  },
  methods: {
    ...mapMutations("subscription/housing", {
      setGridOperatorId: "setGridOperatorId",
    }),
    ...mapActions("subscription/gridOperator", ["getGridOperators"]),
    setViewportHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
};
</script>

<style lang="scss"></style>
