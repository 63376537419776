import { httpService } from "@/api";
import { GridOperator } from "@/store/subscription/housing/type";

const getGridOperators = (): Promise<GridOperator[]> => {
  return httpService.get(
    `${process.env.VUE_APP_API_ENERGY_SUBSCRIPTION}/GridOperators`
  );
};

export default {
  getGridOperators,
};
