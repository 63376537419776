<template>
  <div id="sidebar">
    <div class="sidebar">
      <header class="sidebar__header" @click="goHome()">
        <domuneo-logo v-if="tenant === 'DOM'" />
        <mylight-logo v-else />
      </header>
      <div class="sidebar__content">
        <slot />
      </div>
      <footer class="sidebar__footer"></footer>
    </div>
  </div>
</template>

<script>
import MylightLogo from "@/views/layout/formSubscriptionLayout/mylightLogo";
import DomuneoLogo from "@/views/layout/formSubscriptionLayout/domuneoLogo";
import { RouteName } from "@/store/navigation/type";

export default {
  name: "MainSidebar",
  data() {
    return {
      tenant: process.env.VUE_APP_TENANT,
    };
  },
  components: { DomuneoLogo, MylightLogo },
  methods: {
    goHome() {
      this.$router.push({ name: RouteName.Root });
    },
  },
};
</script>

<style lang="scss" scoped>
#sidebar {
  grid-column-start: 1;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.sidebar {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background-color: $greyBackground;
  width: 200px;
  grid-template-rows: $desktop-height-main-header 1fr 65px;
  display: grid;
  grid-template-columns: 1fr;

  &__header {
    cursor: pointer;
  }
}

@include media("<tablet") {
  #sidebar {
    visibility: hidden;

    .sidebar {
      grid-template-rows: $mobile-height-main-header 1fr 65px;
      -webkit-transform: translate(-100%);
      transform: translate(-100%);
    }
  }
}

.sidebar__header {
  display: flex;
  align-items: center;
  justify-content: center;

  span.icon {
    display: none;
  }
}

@include media("<tablet") {
  .sidebar__header {
    justify-content: left;

    img {
      width: 100px;
    }

    span.icon {
      margin: 0 1rem 0 1.5rem;
      display: inline;
      cursor: pointer;
    }
  }
}

.sidebar__content {
  padding: 1rem 0;
  overflow-y: auto;
  flex-wrap: nowrap;
}

.sidebar__footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
