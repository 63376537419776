export interface OfferState {
  batteryPlansLoading: boolean;
  batteryPlansError: string | null;
  batteryPlans: BatteryPlan[];
  productTypes: ProductType[];
  productType: ProductType;
  batteryCapacity: BatteryCapacity | null;
}

export interface GetOfferQuery {
  batteryCapacity: BatteryCapacity;
  productType: ProductType;
}

export interface Offer {
  batteryCapacity: BatteryCapacity | null;
  productType: ProductType;
}

export interface BatteryPlan {
  capacity: BatteryCapacity;
  price: number;
}

export enum ProductType {
  GREEN100 = "GREEN100",
}

export enum BatteryCapacity {
  HUNDRED = 100,
  TWO_HUNDRED = 200,
  THREE_HUNDRED = 300,
  SIX_HUNDRED = 600,
  NINE_HUNDRED = 900,
  TWELVE_HUNDRED = 1200,
  EIGHTEEN_HUNDRED = 1800,
}
