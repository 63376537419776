export const enum TypeNotification {
  IsSuccess = "is-success",
  IsDanger = "is-danger",
  IsWarning = "is-warning",
}

export interface NotificationState {
  position: string;
  queue: boolean;
  duration: number;
}
