export enum ContractedPower {
  SIX = 6,
  NINE = 9,
  TWELVE = 12,
  FIFTEEN = 15,
  EIGHTEEN = 18,
  TWENTY_FOUR = 24,
  THIRTY = 30,
  THIRTY_SIX = 36,
}

export enum StreetNumberSuffix {
  BIS = "BIS",
  TER = "TER",
  QUATER = "QUATER",
}

export enum ConsumptionInformationType {
  EURO = "EURO",
  WATT = "WATT",
}

export interface HousingState {
  gridOperatorId: string | null;

  contractedPowers: Array<ContractedPower>;
  contractedPower: ContractedPower | null;
  gsrnEANNumber: string;
  streetNumber: string;
  streetNumberSuffixes: Array<StreetNumberSuffix>;
  streetNumberSuffix: StreetNumberSuffix | null;
  street: string;
  postCode: string;
  city: string;
  consumptionInformationType: ConsumptionInformationType;
  consumptionInformationEuros: string;
  consumptionInformationKwh: string;
  loadingValidationGsrnEan: boolean;
  errorValidationGsrnEan: string | null;
  successValidationGsrnEan: boolean;
}

export interface GetHousingQuery {
  gridOperatorId: string;
  contractedPower: ContractedPower;
  gsrnEANNumber: string;
  streetNumber: string;
  streetNumberSuffix: StreetNumberSuffix;
  street: string;
  postCode: string;
  city: string;
  consumptionInformationEuros: number | null;
  consumptionInformationKwh: number | null;
}

export interface Housing {
  gridOperatorId: string | null;
  contractedPower: ContractedPower | null;
  gsrnEANNumber: string;
  streetNumber: string;
  streetNumberSuffix: StreetNumberSuffix | null;
  street: string;
  postCode: string;
  city: string;
  consumptionInformationEuros?: number | null;
  consumptionInformationKwh?: number | null;
}

export interface GridOperator {
  name: string;
  id: string;
  gridOperatorEnum: GridOperatorEnum;
  status: GridOperatorStatus;
}

export enum GridOperatorStatus {
  MANAGED = "MANAGED",
  PENDING = "PENDING",
  UNMANAGED = "UNMANAGED",
}

export enum ErrorValidationGsrnEan {
  FORMAT_INVALID = "FORMAT_INVALID",
  GSRNEAN_NOTFOUND = "GSRNEAN_NOTFOUND",
  SERVER_ENERGYCOM_RESPOND_INVALID = "SERVER_ENERGYCOM_RESPOND_INVALID",
  SERVER_ENERGYCOM_NOT_RESPOND = "SERVER_ENERGYCOM_NOT_RESPOND",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
}

export enum GridOperatorEnum {
  ENEDIS = "Enedis",
  STRASBOURG = "ES",
}
