
export default {
  name: "ReinsuranceFooter",
  methods: {
    getCurrentYear() {
      const date = new Date();
      return date.getFullYear();
    },
  },
};
