<template>
  <div id="mobileStepHeader">
    <subscription-steps-navigation is-mobile />
  </div>
</template>

<script>
import SubscriptionStepsNavigation from "@/views/layout/formSubscriptionLayout/SubscriptionStepsNavigation";

export default {
  name: "MobileStepHeader",
  components: { SubscriptionStepsNavigation },
};
</script>

<style lang="scss" scoped>
#mobileStepHeader {
  grid-column: 2;
  grid-row: 2;
  align-self: center;
  background-color: $lightGrey;
  height: 100%;
  padding-top: 0.5rem;
}

#mobileStepHeader :deep(.step-content) {
  display: none;
}

#mobileStepHeader :deep(.step-navigation) {
  position: absolute;
  z-index: 4;
  bottom: 15px;
  right: 15px;

  .button:first-child {
    margin-right: 5px;
  }
}

#mobileStepHeader :deep(.step-navigation) {
  display: none;
}

@include media("<tablet") {
  #mobileStepHeader {
    grid-column-start: 1;
    grid-column-end: span 2;
  }

  #mobileStepHeader :deep(.step-navigation) {
    display: block;
    position: absolute;
    z-index: 4;
    bottom: 15px;
    right: 15px;

    .button:first-child {
      margin-right: 5px;
    }
  }
}
</style>
